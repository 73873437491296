import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import UpdateCustomerForm from './UpdateCustomerForm'
import { useDispatch, useSelector } from 'react-redux'
import { TabContext, TabPanel } from '@mui/lab'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import { getCustomerById, selectItemById, updateCustomer } from 'app/redux/store/features/customers'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import CustomerAuditLogs from './CustomerAuditLog/CustomerAuditLog'

const UpdateCustomer = () => {
    const [tabTitle, setTabTitle] = React.useState('customerdetails') // Initialize with a default value
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { customerId } = state
    const { customers, loading, error } = useSelector((state) => state.customers)
    const customer = selectItemById(customers, customerId)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(customer.customerId)) dispatch(getCustomerById({ customerId: customerId }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return

        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Customer updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/store/customers')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onTabSelectionChanged = (e, newValue) => {
        setTabTitle(newValue)
    }

    const onUpdateCustomer = async () => {
        setApiCallInProgress(true)
        dispatch(updateCustomer({ ...customer }))
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Customer
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={tabTitle}>
                    <TabList
                        sx={{ bgcolor: 'background.default', m: 2, mb: 0, marginLeft: '23px', marginRight: '23px' }}
                        onChange={onTabSelectionChanged}>
                        <Tab label={'Customer Details'} value={'customerdetails'} sx={{ width: '190px' }} />
                        <Tab label={'Audit Logs'} value={'auditlogs'} sx={{ width: '160px' }} />
                    </TabList>
                    <TabPanel value='customerdetails'>
                        <UpdateCustomerForm customer={customer} updateCustomer={onUpdateCustomer} />
                    </TabPanel>
                    <TabPanel value='auditlogs'>
                        <CustomerAuditLogs customerId={customerId} />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateCustomer
