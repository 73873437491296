import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import 'devextreme-react/tag-box'
import { useSnackbar } from 'notistack'
import AutoNumberForm from '../../AutoNumberForm'
import { getSupplierPaymentAutoNumber, updateSupplierPaymentAutoNumber } from 'app/redux/store/features/autonumbers'

const SupplierPayment = () => {
    const navigate = useNavigate()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { supplierPaymentAutoNumber, loading, error } = useSelector((state) => state.autoNumbers)
    const supplierPayment = { ...supplierPaymentAutoNumber }

    useEffect(() => {
        dispatch(getSupplierPaymentAutoNumber(''))
    }, [dispatch])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onUpdateSupplierPayment = async () => {
        setApiCallInProgress(true)
        dispatch(updateSupplierPaymentAutoNumber(supplierPayment))
        enqueueSnackbar('Supplier Payment Auto Number updated successfully', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    }

    return (
        <React.Fragment>
            <AutoNumberForm
                autoNumberData={supplierPayment}
                heading={'Supplier Payment'}
                onUpdate={onUpdateSupplierPayment}
                reloadAutoNumber={() => dispatch(getSupplierPaymentAutoNumber(''))}
            />
        </React.Fragment>
    )
}

export default SupplierPayment
