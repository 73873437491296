import React from 'react'
import { Navigate } from 'react-router-dom'
import InventoryGrid from '../pages/store/inventory'
import UpdateInventory from '../pages/store/inventory/components/UpdateInventory'
import AddInventory from '../pages/store/inventory/components/AddInventory'
import Page from '@jumbo/shared/Page'
import DefinitionApp from 'app/pages/store/definition/DefinitionApp'
import Shipments from 'app/pages/store/shipments'
import AddSuplier from 'app/pages/store/shipments/components/suppliers/components/AddSupplier'
import UpdateSupplier from 'app/pages/store/shipments/components/suppliers/components/UpdateSupplier'
import ManageSales from 'app/pages/store/sales/ManageSales'
import TxnView from 'app/pages/store/sales/components/txnView/TxnView'
import CustomersGrid from 'app/pages/store/customer/CustomersGrid'
import AddCustomer from 'app/pages/store/customer/components/AddCustomer'
import UpdateCustomer from 'app/pages/store/customer/components/UpdateCustomer'
import BranchSettings from '../pages/store/settings/BranchSettings'
import OrderView from 'app/pages/store/shipments/components/orders/components/orderView/OrderView'
import AddDraftOrder from 'app/pages/store/shipments/components/orders/DraftOrders/components/AddDraftOrder'
import UpdateDraftOrder from 'app/pages/store/shipments/components/orders/DraftOrders/components/UpdateDraftOrder'

const storeRoutes = [
    {
        path: '/store',
        element: <Navigate to={'/store/inventory'} />,
    },
    {
        path: '/store/inventory',
        element: <Page component={InventoryGrid} />,
    },
    {
        path: '/store/inventory/edit/:inventoryId',
        element: <Page component={UpdateInventory} />,
    },
    {
        path: '/store/inventory/addInventory',
        element: <Page component={AddInventory} />,
    },
    {
        path: ['/store/sales/', '/store/sales/:tabTitle'],
        element: <Page component={ManageSales} />,
    },
    {
        path: ['/store/sales/all-transaction/details/:posTxId'],
        element: <Page component={TxnView} />,
    },
    {
        path: ['/store/shipments/', '/store/shipments/:selectedTab'],
        element: <Page component={Shipments} />,
    },
    {
        path: ['/store/shipments/orders/details/:orderId/:onlineShipment'],
        element: <Page component={OrderView} />,
    },
    {
        path: ['/store/customers'],
        element: <Page component={CustomersGrid} />,
    },
    {
        path: ['/store/customers/addcustomer'],
        element: <Page component={AddCustomer} />,
    },
    {
        path: ['/store/customers/edit/:customerId'],
        element: <Page component={UpdateCustomer} />,
    },
    {
        path: ['/store/shipments/supplier/addsupplier'],
        element: <Page component={AddSuplier} />,
    },
    {
        path: '/store/shipments/supplier/edit/:supplierId',
        element: <Page component={UpdateSupplier} />,
    },
    {
        path: ['/store/settings', '/store/settings/:tabTitle', '/store/settings/:tabTitle/:selectedId'],
        element: <Page component={BranchSettings} />,
    },
    {
        path: ['/store/definitions', '/store/definitions/:category', '/store/definitions/:category/:id'],
        element: <Page component={DefinitionApp} />,
    },
    {
        path: ['/store/shipments/draftorders/adddraftorders'],
        element: <Page component={AddDraftOrder} />,
    },
    {
        path: '/store/shipments/draftorders/edit/:stockShipmentId',
        element: <Page component={UpdateDraftOrder} />,
    },
]

export default storeRoutes
