import React from 'react'
import OrderTimeLineList from './OrderTimeLineCard/components/OrderTimeLineList'

const OrderTimeLineCard = ({ order, statusList }) => {
    return (
        <React.Fragment>
            <OrderTimeLineList order={order} statusList={statusList} />
        </React.Fragment>
    )
}

export default OrderTimeLineCard
