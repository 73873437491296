import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    inventoryAutoNumber: {},
    supplierAutoNumber: {},
    customerAutoNumber: {},
    transferAutoNumber: {},
    shipmentAutoNumber: {},
    orderAutoNumber: {},
    inventoryBatchAutoNumber: {},
    transactionAutoNumber: {},
    discountAutoNumber: {},
    transferRequestAutoNumber: {},
    customerPaymentAutoNumber: {},
    supplierPaymentAutoNumber: {},
    loading: false,
    error: null,
}

const autoNumbersSlice = createSlice({
    name: 'autoNumbers',
    initialState,
    reducers: {
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },

        getInventoryNumber: (state, action) => {
            state.inventoryAutoNumber = action.payload
            state.loading = false
        },
        updateInventoryNumber: (state, action) => {
            state.inventoryAutoNumber = action.payload
            state.loading = false
        },

        getSupplierNumber: (state, action) => {
            state.supplierAutoNumber = action.payload
            state.loading = false
        },
        updateSupplierNumber: (state, action) => {
            state.supplierAutoNumber = action.payload
            state.loading = false
        },

        getCustomerNumber: (state, action) => {
            state.customerAutoNumber = action.payload
            state.loading = false
        },
        updateCustomerNumber: (state, action) => {
            state.customerAutoNumber = action.payload
            state.loading = false
        },

        getTransferNumber: (state, action) => {
            state.transferAutoNumber = action.payload
            state.loading = false
        },
        updateTransferNumber: (state, action) => {
            state.transferAutoNumber = action.payload
            state.loading = false
        },

        getShipmentNumber: (state, action) => {
            state.shipmentAutoNumber = action.payload
            state.loading = false
        },
        updateShipmentNumber: (state, action) => {
            state.shipmentAutoNumber = action.payload
            state.loading = false
        },

        getOrderNumber: (state, action) => {
            state.orderAutoNumber = action.payload
            state.loading = false
        },
        updateOrderNumber: (state, action) => {
            state.orderAutoNumber = action.payload
            state.loading = false
        },

        getInventoryBatchNumber: (state, action) => {
            state.inventoryBatchAutoNumber = action.payload
            state.loading = false
        },
        updateInventoryBatchNumber: (state, action) => {
            state.inventoryBatchAutoNumber = action.payload
            state.loading = false
        },

        getTransactionNumber: (state, action) => {
            state.transactionAutoNumber = action.payload
            state.loading = false
        },
        updateTransactionNumber: (state, action) => {
            state.transactionAutoNumber = action.payload
            state.loading = false
        },

        getDiscountNumber: (state, action) => {
            state.discountAutoNumber = action.payload
            state.loading = false
        },
        updateDiscountNumber: (state, action) => {
            state.discountAutoNumber = action.payload
            state.loading = false
        },
        getTransferRequestNumber: (state, action) => {
            state.transferRequestAutoNumber = action.payload
            state.loading = false
        },
        updateTransferRequestNumber: (state, action) => {
            state.transferRequestAutoNumber = action.payload
            state.loading = false
        },
        getCustomerPaymentNumber: (state, action) => {
            state.customerPaymentAutoNumber = action.payload
            state.loading = false
        },
        updateCustomerPaymentNumber: (state, action) => {
            state.customerPaymentAutoNumber = action.payload
            state.loading = false
        },
        getSupplierPaymentNumber: (state, action) => {
            state.supplierPaymentAutoNumber = action.payload
            state.loading = false
        },
        updateSupplierPaymentNumber: (state, action) => {
            state.supplierPaymentAutoNumber = action.payload
            state.loading = false
        },
    },
})

export const {
    apiRequested,
    apiRequestedFailed,
    getInventoryNumber,
    updateInventoryNumber,
    getSupplierNumber,
    updateSupplierNumber,
    getCustomerNumber,
    updateCustomerNumber,
    getTransferNumber,
    updateTransferNumber,
    getShipmentNumber,
    updateShipmentNumber,
    getOrderNumber,
    updateOrderNumber,
    getInventoryBatchNumber,
    updateInventoryBatchNumber,
    getTransactionNumber,
    updateTransactionNumber,
    getDiscountNumber,
    updateDiscountNumber,
    getTransferRequestNumber,
    updateTransferRequestNumber,
    getCustomerPaymentNumber,
    updateCustomerPaymentNumber,
    getSupplierPaymentNumber,
    updateSupplierPaymentNumber,
} = autoNumbersSlice.actions
export default autoNumbersSlice.reducer

export const getInventoryAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/inventory`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getInventoryNumber.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateInventoryAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/inventory/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateInventoryNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSupplierAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/supplier`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSupplierNumber.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateSupplierAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/supplier/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateSupplierNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCustomerAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/customer`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getCustomerNumber.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateCustomerAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/customer/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateCustomerNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransferAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/transfers`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTransferNumber.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateTransferAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/transfers/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateTransferNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getShipmentAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/stockshipment`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getShipmentNumber.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateShipmentAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/stockshipment/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateShipmentNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getOrderAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/stockorder`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getOrderNumber.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateOrderAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/stockorder/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateOrderNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getInventoryBatchAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/inventorybatch`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getInventoryBatchNumber.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateInventoryBatchAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/inventorybatch/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateInventoryBatchNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransactionAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/transaction`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTransactionNumber.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateTransactionAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/transaction/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateTransactionNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getDiscountAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/discount`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getDiscountNumber.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateDiscountAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/discount/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateDiscountNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransferRequestAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/transfer/request`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTransferRequestNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateTransferRequestAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/transfer/request/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateTransferRequestNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCustomerPaymentAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/customer/payment`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getCustomerPaymentNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateCustomerPaymentAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/customer/payment/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateCustomerPaymentNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSupplierPaymentAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/supplier/payment`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSupplierPaymentNumber.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateSupplierPaymentAutoNumber = (filter) => {
    let url = `/Config/branch/autonumbers/supplier/payment/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: updateSupplierPaymentNumber.type,
        onError: apiRequestedFailed.type,
    })
}
