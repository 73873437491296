import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import UpdateBranchForm from './UpdateBranchForm'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import LinkedUserGrid from './LinkedUserGrid'
import { TabContext, TabPanel } from '@mui/lab'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import CustomStore from 'devextreme/data/custom_store'
import companyAndBranchServices from 'app/services/settings/company/companyAndBranchServices'
import DataSource from 'devextreme/data/data_source'

const UpdateBranch = () => {
    const [tabTitle, setTabTitle] = React.useState('details') // Initialize with a default value
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    const onTabSelectionChanged = (e, newValue) => {
        setTabTitle(newValue)
    }

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const comapanyLookupList = useCallback(async (searchTerm) => {
        try {
            return await companyAndBranchServices.getCompanyLookupList({ companyName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const companyLookupById = useCallback(async (key) => {
        try {
            return await companyAndBranchServices.getCompanyLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const companyLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'companyId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await comapanyLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await companyLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const companyEditorOptions = {
        dataSource: companyLookupStore,
        displayExpr: 'companyName',
        valueExpr: 'companyId',
        searchEnabled: true,
        showClearButton: true,
        readOnly: true,
    }

    const storeTypeLookupList = useCallback(async (searchTerm) => {
        try {
            return await companyAndBranchServices.getStoreTypeLookupList({ typeName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const storeTypeLookupById = useCallback(async (key) => {
        try {
            return await companyAndBranchServices.getStoreTypeLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const storeTypeLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await storeTypeLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (typeof key === 'string') {
                            return utilServices.emptyList()
                        }
                        return await storeTypeLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const storeEditorOptions = {
        dataSource: storeTypeLookupStore,
        displayExpr: 'typeName',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Branch
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={tabTitle}>
                    <TabList sx={{ bgcolor: 'background.default', m: 2, mb: 0 }} onChange={onTabSelectionChanged}>
                        <Tab label={'Details'} value={'details'} sx={{ width: '160px' }} />
                        <Tab label={'Linked Users'} value={'linked-users'} sx={{ width: '180px' }} />
                    </TabList>
                    <TabPanel value='details'>
                        <UpdateBranchForm
                            companyEditorOptions={companyEditorOptions}
                            storeEditorOptions={storeEditorOptions}
                        />
                    </TabPanel>
                    <TabPanel value='linked-users'>
                        <LinkedUserGrid />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateBranch
