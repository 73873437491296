import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { getSyncConfig, updateSyncConfig } from 'app/redux/store/features/configs'
import { useMediaQuery } from '@mui/material'

const SyncConfig = () => {
    const { handleSubmit } = useForm()
    const startTime = '05:00:00'
    const endTime = '16:59:59'
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const { sync, loading, error } = useSelector((state) => state.config)
    let [syncData, setSyncData] = useState({ ...sync })
    const [labelLocation, setLabelLocation] = useState('left')
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    useEffect(() => {
        if (!apiCallInProgress || error) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [error, loading])

    useEffect((e) => {
        setApiCallInProgress(true)
        function getSync() {
            dispatch(getSyncConfig())
        }
        getSync()
    }, [])

    useEffect(() => {
        let sConfig = { ...sync }
        if (!sConfig?.branchDayStartTime) {
            sConfig.branchDayStartTime = startTime
        }
        if (!sConfig?.branchDayEndTime) {
            sConfig.branchDayEndTime = endTime
        }
        setSyncData({
            ...sync,
            branchDayStartTime: sConfig.branchDayStartTime,
            branchDayEndTime: sConfig.branchDayEndTime,
        })
    }, [sync])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    function reloadSyncConfig() {
        dispatch(getSyncConfig())
    }

    const dialogBoxButtons = () => {
        return (
            <Stack direction={'row'} spacing={2} sx={{ m: 'auto', ml: 10.5, mb: 2 }}>
                <LoadingButton
                    variant='contained'
                    color='secondary'
                    onClick={async () => {
                        await reloadSyncConfig()
                        hideDialog()
                    }}
                    size='large'
                    sx={{ width: 100 }}>
                    {' '}
                    yes
                </LoadingButton>
                <LoadingButton
                    variant='outlined'
                    color='secondary'
                    onClick={hideDialog}
                    size='large'
                    sx={{ width: 100 }}>
                    {' '}
                    NO
                </LoadingButton>
            </Stack>
        )
    }

    const onUpdateSyncConfig = useCallback(async () => {
        setApiCallInProgress(true)
        const responseData = dispatch(updateSyncConfig(syncData))
        if (responseData) {
            // On sync config data updated successfull
            enqueueSnackbar('Sync Config data is updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }, [dialogBoxButtons])

    const submitButtonOptions = {
        text: 'Save',
        type: 'danger',
        width: 120,
        icon: 'check',
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () =>
            showDialog({
                title: 'You have unsaved changes, do you want to cancel and reload?',
                content: dialogBoxButtons(),
            }),
    }

    const validationRules = {
        maxUpload: [{ type: 'required', message: 'Maximum Upload is required.' }],
    }

    const dateTimeEditorOption = {
        displayFormat: 'shortDateShortTime',
        type: 'datetime',
        pickerType: 'calendar',
        useMaskBehavior: true,
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Sync Config
                    </Typography>
                }
                sx={{ m: 0.5, mt: 1 }}
                headerSx={{
                    height: 40,
                    bgcolor: 'secondary.main',
                }}>
                <form onSubmit={handleSubmit(onUpdateSyncConfig)}>
                    <Form
                        mode='form'
                        formData={syncData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}
                        labelLocation={labelLocation}>
                        <GroupItem>
                            <ColCountByScreen lg={2} md={1} sm={1} xs={1} />
                            <GroupItem>
                                <GroupItem caption='Configuration' cssClass='form-group-item '>
                                    <GroupItem cssClass='form-group-item-margin'>
                                        <SimpleItem
                                            dataField='referentialResetBeforeNextUpload'
                                            editorType='dxCheckBox'>
                                            <Label text='Reset All Data Before Next Upload ' />
                                        </SimpleItem>
                                        <SimpleItem dataField='recalculateDashboardSummary' editorType='dxCheckBox'>
                                            <Label text='Recalculate Dashboard Graph' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='maxUpload'
                                            editorType='dxNumberBox'
                                            validationRules={validationRules.maxUpload}>
                                            <Label text='Max Upload' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                                <GroupItem caption='Sale Item' cssClass='form-group-item '>
                                    <GroupItem cssClass='form-group-item-margin'>
                                        <SimpleItem
                                            dataField='saleItemStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Sale Item Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='saleItemCatStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Sale Item Category Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='saleItemSubCatStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Sale Item SubCategory Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='saleTransTypeStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Sale Transaction Type Start Date' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                                <GroupItem caption='Inventory' cssClass='form-group-item'>
                                    <GroupItem cssClass='form-group-item-margin'>
                                        <SimpleItem
                                            dataField='inventoryStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Inventory Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='invCatStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Inventory Category Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='invTypeStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Inventory Type Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='invSubCatStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Inventory SubCategory Start Date' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                                <GroupItem caption='Orders' cssClass='form-group-item'>
                                    <GroupItem cssClass='form-group-item-margin' colSpan={6}>
                                        <GroupItem>
                                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                                            <SimpleItem
                                                dataField='shipmentResetBeforeNextUpload'
                                                editorType='dxCheckBox'>
                                                <Label text='Orders Reset Before Next Upload' />
                                            </SimpleItem>
                                        </GroupItem>
                                        <SimpleItem
                                            dataField='shipmentStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Orders Start Date' />
                                        </SimpleItem>
                                        <EmptyItem />
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem>
                                <GroupItem caption='Defintion' cssClass='form-group-item'>
                                    <GroupItem cssClass='form-group-item-margin'>
                                        <SimpleItem
                                            dataField='chartOfAccountStartDate'
                                            editorOptions={dateTimeEditorOption}
                                            editorType='dxDateBox'>
                                            <Label text='Chart Of Account Start Date' editorType='dxDateBox' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='chartTaxesStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Chart Taxes Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='discountsStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Discounts Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='customerStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Customer Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='suppliersStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Suppliers Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='supplierCompaniesStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Supplier Companies Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='stockStoragesStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Stock Storages Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='perActivityTypeStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Person Actvity Type Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='personCategoryStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Person Category Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='terminalStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Terminal Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='transStatusStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Transaction Status Start Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='userStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='User Start Date' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                                <GroupItem />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem caption='Transactions' cssClass='form-group-item'>
                                    <GroupItem cssClass='form-group-item-margin' colSpan={6}>
                                        <GroupItem>
                                            <SimpleItem dataField='txnResetBeforeNextUpload' editorType='dxCheckBox'>
                                                <Label text='Transaction Reset Before Next Upload' />
                                            </SimpleItem>
                                        </GroupItem>
                                        <SimpleItem
                                            dataField='txnStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Txn Start Date' />
                                        </SimpleItem>
                                        <EmptyItem />
                                    </GroupItem>
                                </GroupItem>
                                <GroupItem caption='Transfers' cssClass='form-group-item'>
                                    <GroupItem cssClass='form-group-item-margin' colSpan={6}>
                                        <GroupItem>
                                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                                            <SimpleItem
                                                dataField='transfersResetBeforeNextUpload'
                                                editorType='dxCheckBox'>
                                                <Label text='Transfers Reset Before Next Upload' />
                                            </SimpleItem>
                                        </GroupItem>
                                        <SimpleItem
                                            dataField='transferStartDate'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Transfer Start Date' />
                                        </SimpleItem>
                                        <EmptyItem />
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption='Shift' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin' colSpan={6}>
                                    <SimpleItem
                                        dataField='branchDayStartTime'
                                        editorType='dxDateBox'
                                        editorOptions={{
                                            displayFormat: 'shortTime',
                                            pickerType: 'rollers',
                                            type: 'time',
                                            useMaskBehavior: true,
                                        }}>
                                        <Label text='Branch Day Start Time' />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField='branchDayEndTime'
                                        editorType='dxDateBox'
                                        editorOptions={{
                                            displayFormat: 'shortTime',
                                            pickerType: 'rollers',
                                            type: 'time',
                                            useMaskBehavior: true,
                                        }}>
                                        <Label text='Branch Day End Time' />
                                    </SimpleItem>
                                    <EmptyItem />
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem buttonOptions={submitButtonOptions} horizontalAlignment='right' />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default SyncConfig
