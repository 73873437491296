import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { SelectBox } from 'devextreme-react/select-box'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { getTerminalLookupList, getCashReasonLookupList } from 'app/redux/store/features/reports'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import useDebouncedInput from 'app/pages/reports/hooks/useDebouncedInput'

const CashRegisterReportFilter = ({ mutation }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getTerminalLookupList(''))
        dispatch(getCashReasonLookupList(''))
    }, [])

    const { terminalTypes, cashReasonTypes } = useSelector((state) => state.reports)

    const terminalLookupStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'terminalId',
                    data: terminalTypes.data,
                    totalCount: terminalTypes.length,
                }),
                reshapeOnPush: true,
            }),
        [terminalTypes]
    )

    const cashReasonTypeLookupStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'cashReasonTypeId',
                    data: cashReasonTypes.data,
                    totalCount: cashReasonTypes.length,
                }),
                reshapeOnPush: true,
            }),
        [cashReasonTypes]
    )

    let initialValues = {
        startDate: DateUtils.getUtcStartDate(10).toISOString().slice(0, 16),
        endDate: DateUtils.getUtcEndDate().toISOString().slice(0, 16),
        CashierName: '',
        comments: '',
    }

    const [filter, setFilter] = useState({
        TerminalId: null,
        CashReasonTypeId: null,
    })

    const onFormSubmit = (startDate, endDate, CashierName, comments) => {
        mutation.mutate({
            startDate: startDate.toFilterStartDateTime(),
            endDate: endDate.toFilterEndDateTime(),
            CashierName: CashierName,
            comments: comments,
            ...filter,
            sort: '',
        })
    }

    const onFilterSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        onFormSubmit(data.startDate, data.endDate, data.CashierName, data.comments)
        setSubmitting(false)
    }

    const handleClear = () => {
        setFilter({
            TerminalId: null,
            CashReasonTypeId: null,
        })
    }

    const {
        value: TerminalNumberValue,
        debouncedValue: debouncedTerminalNumberValue,
        onChange: onTerminalNumberSearch,
    } = useDebouncedInput('', 300)

    useEffect(() => {
        dispatch(getTerminalLookupList(TerminalNumberValue))
    }, [debouncedTerminalNumberValue])

    const onTerminalValueChanged = (value) => {
        setFilter({ ...filter, TerminalId: value?.terminalId })
    }

    const {
        value: CashResonTypeValue,
        debouncedValue: debouncedCashResonTypeValue,
        onChange: onCashResonTypeSearch,
    } = useDebouncedInput('', 300)

    useEffect(() => {
        dispatch(getCashReasonLookupList(CashResonTypeValue))
    }, [debouncedCashResonTypeValue])

    const onCashReasonTypeValueChanged = (value) => {
        setFilter({ ...filter, CashReasonTypeId: value?.cashReasonTypeId })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ display: '', borderRadius: 0 }}>
                        <Divider />
                        <Div
                            className='report-filter-parent'
                            style={{
                                display: 'flex',
                                margin: '0% 1.5% 1.5% 1.5%',
                                flexWrap: 'wrap',
                            }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='startDate'
                                        type='datetime-local'
                                        defaultValue={initialValues.startDate}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='endDate'
                                        type='datetime-local'
                                        defaultValue={initialValues.endDate}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Cashier Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='CashierName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Comments</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='comments' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Terminal</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={terminalLookupStore}
                                        displayExpr={'name'}
                                        value={filter.TerminalId}
                                        searchEnabled={true}
                                        onValueChange={onTerminalValueChanged}
                                        onInput={onTerminalNumberSearch}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Cash Reason Type</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={cashReasonTypeLookupStore}
                                        displayExpr={'name'}
                                        value={filter.CashReasonTypeId}
                                        searchEnabled={true}
                                        onValueChange={onCashReasonTypeValueChanged}
                                        onInput={onCashResonTypeSearch}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default CashRegisterReportFilter
