import React from 'react'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import Divider from '@mui/material/Divider'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'

const CompanyGridSidebarFilter = ({ mutation, display, filterIconColor }) => {
    let initialValues = {
        companyName: '',
        contactName: '',
    }
    const onFormSubmit = async (companyName, contactName) => {
        await mutation.mutate({
            companyName: companyName,
            contactName: contactName,
        })
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(data.companyName, data.contactName)
        setSubmitting(false)
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onSubmit={onFilterSubmit}
            onReset={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper sx={{ display: display, borderRadius: 0 }}>
                        <Divider />
                        <Div style={{ display: 'flex', margin: '0% 1.5% 4.6% 2.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Company Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 100 }} size='small' name='companyName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Contact Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 100 }} size='small' name='contactName' />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <Div>
                                        <LoadingButton
                                            size={'small'}
                                            type='submit'
                                            onClick={() => filterIconColor(true)}
                                            variant={'outlined'}
                                            loading={isSubmitting || mutation.isLoading}>
                                            <SearchIcon />
                                        </LoadingButton>
                                    </Div>
                                    <Div>
                                        <LoadingButton
                                            size={'small'}
                                            type={'reset'}
                                            onClick={() => filterIconColor(false)}
                                            variant={'outlined'}>
                                            <AutorenewRoundedIcon />
                                        </LoadingButton>
                                    </Div>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default CompanyGridSidebarFilter
