import React, { useCallback } from 'react'
import { ListItemText, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import { useNavigate } from 'react-router-dom'
import Span from '@jumbo/shared/Span'
import { useDispatch } from 'react-redux'
import { getBranchById } from 'app/redux/store/features/branches'

const LeftInfoPanelItem = ({ item, min }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleRecentRenewedListClick = useCallback(
        ({ item }) => {
            dispatch(getBranchById({ branchId: item.branchId, companyId: item.fkCompanyId }))
            navigate(`/settings/companies/${item.fkCompanyId}/branches/edit/${item.branchId}`, {
                state: { branchId: item.branchId, companyId: item.fkCompanyId },
            })
        },
        [dispatch, navigate]
    )

    return (
        <ListItemButton
            component='li'
            sx={{ p: (theme) => theme.spacing(1, 3) }}
            onClick={() => handleRecentRenewedListClick({ item })}>
            <ListItemText
                sx={{ flexBasis: '30%', marginRight: 3 }}
                primary={
                    <Typography variant='h5' color='text.primary' sx={{ width: min ? '120px' : '120px' }} mb={0.5}>
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.companyName}
                        </Span>
                        <br />
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.branchName}
                        </Span>
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 3,
                }}
                primary={
                    <Typography
                        variant='body1'
                        sx={{ fontSize: 13, width: min ? '120px' : '120px' }}
                        mb={0.5}
                        color='text.primary'>
                        <Span sx={{ color: 'text.primary', mr: 1, whiteSpace: 'normal' }}>{item.phoneNo}</Span>
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography
                        variant='body1'
                        sx={{ fontSize: 13, width: min ? '140px' : '140px', wordWrap: min ? 'break-word' : 'normal' }}
                        color='text.primary'>
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.address}
                        </Span>
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography
                        variant='body1'
                        sx={{ fontSize: 13, width: min ? '100px' : '100px' }}
                        color='text.primary'>
                        <Span sx={{ color: 'text.primary', mr: 1, whiteSpace: 'normal' }}>
                            {formatDateTime(item.licenseStartDate)}
                        </Span>
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography
                        variant='body1'
                        sx={{ fontSize: 13, width: min ? '100px' : '100px' }}
                        color='text.primary'>
                        <Span sx={{ color: 'text.primary', mr: 1, whiteSpace: 'normal' }}>
                            {formatDateTime(item?.licenseEndDate)}
                        </Span>
                    </Typography>
                }
            />
        </ListItemButton>
    )
}

export default LeftInfoPanelItem
