import React from 'react'
import { Divider, Card, CardContent, ListItemText, Typography, List, Stack, useMediaQuery } from '@mui/material'
import Grid from '@mui/material/Grid'
import CommentIcon from '@mui/icons-material/Comment'
import Span from '@jumbo/shared/Span'
import Button from '@mui/material/Button'
import { useSnackbar } from 'notistack'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useDispatch, useSelector } from 'react-redux'
import { updateOrderStatus } from 'app/redux/store/features/orders'
import OrderTimeLineCard from './OrderTimeLineCard'
import { OrderTimeLineData } from './OrderTimeLineCard/components/data'
import { useJumboTheme } from '@jumbo/hooks'

const RightInfoCard = ({ order, onlineShipment }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const { error } = useSelector((state) => state.orders)
    const dispatch = useDispatch()
    const statusList = OrderTimeLineData.filter((item) => item.id >= 1 && item.id <= 3)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    const hideDialogAndRefreshOrderList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Order Status Approved Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onOrderChangeStatus = async ({ order }) => {
        showDialog({
            variant: 'confirm',
            title: 'Do you want to approve and submit the order?',
            onYes: () => {
                dispatch(updateOrderStatus({ orderId: order.orderId, orderStatus: 'Approved' }))
                hideDialogAndRefreshOrderList()
            },
            onNo: hideDialog,
        })
    }

    return (
        <Grid>
            <Card sx={{ height: sm ? 'auto' : 310 }}>
                <CardContent>
                    <Stack direction={'row'}>
                        <Grid
                            item
                            xs={6}
                            sx={{
                                width: '45%',
                                display: 'flex',
                                alignItems: 'left',
                                marginLeft: '2%',
                            }}>
                            <List sx={{ width: '45%' }}>
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <CommentIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Comments
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2, width: '100%' }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='secondary.dark'>
                                        {order.comments && (
                                            <pre>
                                                <Span
                                                    style={{
                                                        fontSize: '1rem',
                                                        color: 'secondary.dark',
                                                        fontFamily: 'Lobster, cursive',
                                                        fontWeight: '400',
                                                        lineHeight: 1.5,
                                                        whiteSpace: 'pre-wrap',
                                                    }}>
                                                    {order.comments + ' '}
                                                </Span>
                                            </pre>
                                        )}
                                    </Typography>
                                </ListItemText>
                            </List>
                        </Grid>

                        <Grid item xs={6} sx={{ width: '45%', display: 'flex', alignItems: 'right', ml: 1.9 }}>
                            {onlineShipment && (
                                <Stack direction='column'>
                                    <OrderTimeLineCard order={order} statusList={statusList} />
                                    {order.orderStatus === 'Draft' && (
                                        <Button
                                            variant='outlined'
                                            sx={{ height: '40px', width: '160px' }}
                                            onClick={() => onOrderChangeStatus({ order })}>
                                            Approve Order
                                        </Button>
                                    )}
                                </Stack>
                            )}
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
            <Grid item xs={6} sx={{ marginTop: '-7.5%', marginLeft: '12%' }}></Grid>
        </Grid>
    )
}

export default RightInfoCard
