import React, { useMemo, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import UpdateSupplierForm from './UpdateSupplierForm'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import supplierService from 'app/services/shipments/supplier/supplierServices'
import utilServices from 'app/services/util-services'
import { useSnackbar } from 'notistack'
import { TabContext, TabPanel } from '@mui/lab'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Typography } from '@mui/material'
import SupplierAuditLogs from './SupplierAuditLog/SupplierAuditLogs'

const UpdateSupplier = () => {
    const [tabTitle, setTabTitle] = React.useState('supplierdetails')
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { supplierId } = state

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const supplierComapanyLookupList = useCallback(async (searchTerm) => {
        try {
            return await supplierService.getSupplierCompanyLookupList({ supplierCompanyName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierCompanyLookupById = useCallback(async (key) => {
        try {
            return await supplierService.getSupplierCompanyLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierCompanyLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'supplierCompanyId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await supplierComapanyLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return await utilServices.emptyList()

                        return supplierCompanyLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const companyEditorOptions = {
        dataSource: supplierCompanyLookupStore,
        displayExpr: 'supplierCompanyName',
        valueExpr: 'supplierCompanyId',
        searchEnabled: true,
        showClearButton: true,
    }

    const onTabSelectionChanged = (e, newValue) => {
        setTabTitle(newValue)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Supplier
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={tabTitle}>
                    <TabList
                        sx={{ bgcolor: 'background.default', m: 2, mb: 0, marginLeft: '23px', marginRight: '23px' }}
                        onChange={onTabSelectionChanged}>
                        <Tab label={'Supplier Details'} value={'supplierdetails'} sx={{ width: '190px' }} />
                        <Tab label={'Audit Logs'} value={'auditlogs'} sx={{ width: '160px' }} />
                    </TabList>
                    <TabPanel value='supplierdetails'>
                        <UpdateSupplierForm supplierId={supplierId} companyEditorOptions={companyEditorOptions} />
                    </TabPanel>
                    <TabPanel value='auditlogs'>
                        <SupplierAuditLogs supplierId={supplierId} />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateSupplier
