import React, { useEffect, useState, useMemo } from 'react'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    VirtualTable,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import { useJumboTheme } from '@jumbo/hooks'

const SupplierAuditLogGridData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { supplierAuditLogs, loading, error } = useSelector((state) => state.suppliers)
    const dateColumns = ['auditDate', 'auditEndDate']
    const [sorting, setSorting] = useState([{ columnName: 'auditDate', direction: 'asc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const columns = [
        { name: 'auditDate', title: 'Audit Date' },
        { name: 'referenceDate', title: 'Refrence Date' },
        { name: 'branchName', title: 'Branch' },
        { name: 'auditByStaffName', title: 'Staff Name' },
        { name: 'auditType', title: 'Audit Type' },
        { name: 'auditSource', title: 'Audit Source' },
        { name: 'reference', title: 'Refrence' },
        { name: 'message', title: 'Message' },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'auditDate', width: min ? '130px' : '13%', minWidth: '130px', wordWrapEnabled: true },
            { columnName: 'referenceDate', width: min ? '130px' : '13%', minWidth: '130px', wordWrapEnabled: true },
            { columnName: 'branchName', width: min ? '100px' : '10%', minWidth: '100px', wordWrapEnabled: true },
            { columnName: 'auditByStaffName', width: min ? '110px' : '10%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'auditType', width: min ? '100px' : '8%', minWidth: '100px', wordWrapEnabled: true },
            { columnName: 'auditSource', width: min ? '100px' : '9%', minWidth: '100px', wordWrapEnabled: true },
            { columnName: 'reference', width: min ? '110px' : '10%', minWidth: '110px', wordWrapEnabled: true },
            {
                columnName: 'message',
                title: 'Message',
                width: min ? '270px' : '27%',
                minWidth: '270px',
                wordWrapEnabled: true,
            },
        ],
        [min]
    )
    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [
        { columnName: 'auditType', sortingEnabled: false },
        { columnName: 'auditSource', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={supplierAuditLogs.data} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={supplierAuditLogs.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default SupplierAuditLogGridData
