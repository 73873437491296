import React, { useCallback } from 'react'
import { ListItemText, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import { useNavigate } from 'react-router-dom'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'
import Span from '@jumbo/shared/Span'

const RightInfoPanelItem = ({ item, min }) => {
    const navigate = useNavigate()

    const handleFutureExpiryListClick = useCallback(
        ({ item }) => {
            navigate(`/settings/companies/${item.fkCompanyId}/branches/edit/${item.branchId}`, {
                state: { branchId: item.branchId, companyId: item.fkCompanyId },
            })
        },
        [navigate]
    )

    return (
        <ListItemButton
            key={item.id}
            component='li'
            sx={{ p: (theme) => theme.spacing(1, 3) }}
            onClick={() => handleFutureExpiryListClick({ item })}>
            <ProductionQuantityLimitsIcon sx={{ marginRight: '20px' }} />
            <ListItemText
                sx={{ flexBasis: '30%', marginRight: 3 }}
                primary={
                    <Typography variant={'body1'} mb={0.5} sx={{ marginRight: '10px', width: min ? '120px' : '120px' }}>
                        <Span
                            sx={{
                                color: 'text.primary',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal',
                                mr: '1',
                            }}>
                            {item.companyName}
                        </Span>
                        <br />
                        <Span
                            sx={{
                                color: 'text.primary',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal',
                                mr: '1px !important',
                            }}>
                            {item.branchName}
                        </Span>
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 3,
                }}
                primary={
                    <Typography
                        variant='body1'
                        sx={{ fontSize: 13, marginRight: 30, width: min ? '120px' : '120px' }}
                        mb={0.5}
                        color='text.primary'>
                        <Span sx={{ color: 'text.primary', mr: 1, whiteSpace: 'normal' }}>{item.phoneNo}</Span>
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography
                        variant='body1'
                        sx={{ fontSize: 13, width: min ? '140px' : '140px' }}
                        color='text.primary'>
                        <Span sx={{ color: 'text.primary', mr: 1, whiteSpace: 'normal' }}>{item.address}</Span>
                    </Typography>
                }
            />
            <ListItemText
                primary={
                    <Typography
                        variant='body1'
                        sx={{ fontSize: 13, width: min ? '100px' : '100px' }}
                        color='text.primary'>
                        <Span
                            sx={{
                                color: 'text.primary',
                                mr: 1,
                                whiteSpace: 'normal',
                            }}>
                            {formatDateTime(item?.licenseStartDate)}
                        </Span>
                    </Typography>
                }
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
            />
            <ListItemText
                primary={
                    <Typography
                        variant='body1'
                        sx={{ fontSize: 13, width: min ? '120px' : '120px' }}
                        color='text.primary'>
                        <Span
                            sx={{
                                color: 'text.primary',
                                mr: 1,
                                whiteSpace: 'normal',
                            }}>
                            {formatDateTime(item?.licenseEndDate)}
                        </Span>
                    </Typography>
                }
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
            />
        </ListItemButton>
    )
}

export default RightInfoPanelItem
