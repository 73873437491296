import jwtAuthAxios from '../../../auth/jwtAuth'

const definitionServices = {}

definitionServices.getAccountingDefinitionList = async () => {
    return {
        definitions: [
            {
                id: 'chart-of-account',
                name: 'Chart of Account',
                category: 'accounting',
                description: 'This Defintions contains Chart of Account Defintions info',
            },
            {
                id: 'tax-codes',
                name: 'Tax Codes',
                category: 'accounting',
                description: 'This Defintions contains Tax Codes Defintions info',
            },
        ],
    }
}

definitionServices.getInventoryDefinitionList = async () => {
    return {
        definitions: [
            {
                id: 'inventory-types',
                name: 'Inventory Types',
                category: 'inventory',
                description: 'This Defintions contains Inventory Types Defintions info',
            },
            {
                id: 'inventory-category',
                name: 'Inventory Category',
                category: 'inventory',
                description: 'This Defintions contains Inventory Category Defintions info',
            },
            {
                id: 'inventory-sub-category',
                name: 'Inventory Sub-Category',
                category: 'inventory',
                description: 'This Defintions contains Inventory Sub-Category Defintions info',
            },
            {
                id: 'stock-locations',
                name: 'Stock Locations',
                category: 'inventory',
                description: 'Stock Storage locations for inventories',
            },
            {
                id: 'unit-of-measure',
                name: 'Unit of Measure',
                category: 'inventory',
                description: 'This Defintions contains Unit of Measure Defintions info',
            },
        ],
    }
}
definitionServices.getStockStorageLookupList = async (filter) => {
    console.log('filter in getStockStorageLookupList  = ', filter)

    let url = filter?.storageName
        ? `/Inventory/stockstorages/lookup/list?search=${filter.storageName}`
        : `/Inventory/stockstorages/lookup/list`

    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}
definitionServices.getStockStorageLookupListById = async (key) => {
    let url = `/Inventory/stockstorages/${key}`

    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

export default definitionServices
