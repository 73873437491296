import React from 'react'
import { useParams } from 'react-router-dom'
import Div from '@jumbo/shared/Div'
import Inventory from '../../autoNumberList/components/inventory/Inventory'
import Supplier from '../../autoNumberList/components/supplier/Supplier'
import Customer from '../../autoNumberList/components/customer/Customer'
import TransferRequest from '../../autoNumberList/components/transferRequest/TransferRequest'
import Shipment from '../../autoNumberList/components/shipment/Shipment'
import Order from '../../autoNumberList/components/order/Order'
import InventoryBatch from '../../autoNumberList/components/inventoryBatch/InventoryBatch'
import Transaction from '../../autoNumberList/components/transaction/Transaction'
import Discount from '../../autoNumberList/components/discount/Discount'
import TransferInventory from '../../autoNumberList/components/transfer/Transfer'
import CustomerPayment from '../../autoNumberList/components/customerPayment/CustomerPayment'
import SupplierPayment from '../../autoNumberList/components/supplierPayment/SupplierPayment'

const ActiveAutoNumberView = () => {
    const { selectedId } = useParams()

    const AutoNumberList = {
        inventory: Inventory,
        supplier: Supplier,
        customer: Customer,
        transferRequest: TransferRequest,
        transferInventory: TransferInventory,
        shipment: Shipment,
        order: Order,
        inventoryBatch: InventoryBatch,
        transaction: Transaction,
        discount: Discount,
        customerPayment: CustomerPayment,
        supplierPayment: SupplierPayment,
    }

    if (!selectedId) {
        return <Div>{React.createElement(AutoNumberList['inventory'])}</Div>
    } else {
        return <Div>{React.createElement(AutoNumberList[selectedId])}</Div>
    }
}

export default ActiveAutoNumberView
