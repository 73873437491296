export const defaultOrderItem = {
    orderItemId: null,
    fkStockShipmentId: null,
    invName: '',
    invNo: '',
    inventoryBasePrice: 0,
    fkInventoryId: null,
    qtyOrdered: 0,
    orderPrice: 0,
    salePrice: 0,
    discountRate: 0,
    taxRate: 0,
    orderPricePercentage: 0,
    salePricePercentage: 0,
    profitMargin: 0,
    storageName: '',
    taxAmount: 0,
}
