import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'
import { apiRequested, apiRequestedFailed, getCompanys, updateExistingCompany } from './company'

const initialState = {
    global: {},
    sync: {},
    branch: {},
    report: {},
    branchConfig: {},
    indicator: { inProgress: false },
    loading: false,
    error: null,
}

const configSlice = createSlice({
    name: 'configs',
    initialState,
    reducers: {
        getGlobalConfigSettings: (state, action) => {
            state.global = action.payload
            state.loading = false
        },
        updateGlobalConfigSettings: (state, action) => {
            state.global = action.payload
            state.loading = false
        },
        getBranchConfigSettings: (state, action) => {
            state.branchConfig = action.payload
            state.loading = false
        },
        updateBranchConfigSettings: (state, action) => {
            state.branchConfig = action.payload
            state.loading = false
        },
        getSyncStatusSettings: (state, action) => {
            state.branch = action.payload
            state.loading = false
        },
        getSyncConfigSettings: (state, action) => {
            state.sync = action.payload
            state.loading = false
        },
        updateSyncSettings: (state, action) => {
            state.sync = action.payload
            state.loading = false
        },
        getReportConfigSettings: (state, action) => {
            state.report = action.payload
            state.loading = false
        },
        getIndicator: (state, action) => {
            state.indicator = action.payload
            state.loading = false
        },
        updateIndicator: (state, action) => {
            state.indicator = action.payload
            state.loading = false
        },
    },
})

export const {
    getGlobalConfigSettings,
    updateGlobalConfigSettings,
    getSyncStatusSettings,
    getSyncConfigSettings,
    updateSyncSettings,
    getReportConfigSettings,
    getIndicator,
    getBranchConfigSettings,
    updateIndicator,
    updateBranchConfigSettings,
} = configSlice.actions

export default configSlice.reducer

export const getSyncStatus = () => {
    let url = `/Config/branch`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: {},
        onStart: apiRequested.type,
        onSuccess: getSyncStatusSettings.type,
        onError: apiRequestedFailed.type,
    })
}

export const getReportConfig = () => {
    let url = `/Config/report`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: {},
        onStart: apiRequested.type,
        onSuccess: getReportConfigSettings.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSyncConfig = () => {
    let url = `/Config/sync/settings`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: {},
        onStart: apiRequested.type,
        onSuccess: getSyncConfigSettings.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateSyncConfig = (sync) => {
    let url = `/Config/sync/settings/update`
    return apiCallBegan({
        url: url,
        method: 'POST',
        data: sync,
        onStart: apiRequested.type,
        onSuccess: updateSyncSettings.type,
        onError: apiRequestedFailed.type,
    })
}

export const getGlobalConfig = () => {
    let url = `/Config/global/settings`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: {},
        onStart: apiRequested.type,
        onSuccess: getGlobalConfigSettings.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateGlobalConfig = (global) => {
    let url = `/Config/global/settings/update`
    return apiCallBegan({
        url: url,
        method: 'POST',
        data: global,
        onStart: apiRequested.type,
        onSuccess: updateGlobalConfigSettings.type,
        onError: apiRequestedFailed.type,
    })
}

export const getBranchConfig = () => {
    let url = `/Config/branch/settings`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: {},
        onStart: apiRequested.type,
        onSuccess: getBranchConfigSettings.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateBranchConfig = (branchConfig) => {
    let url = `/Config/branch/settings/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: branchConfig,
        onStart: apiRequested.type,
        onSuccess: updateBranchConfigSettings.type,
        onError: apiRequestedFailed.type,
    })
}
