import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { addBranch } from 'app/redux/store/features/branches'
import { PatternRule, RequiredRule } from 'devextreme-react/data-grid'
import { useSnackbar } from 'notistack'

const AddBranchForm = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.branches)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
        else {
            enqueueSnackbar('Branch added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/branches')
        }
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate(`/settings/branches`),
    }

    const phoneEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }
    const phonePattern = /^\+\d{10,15}$/

    const validationRules = {
        branchName: [{ type: 'required', message: 'Branch Name is required.' }],
        branchType: [{ type: 'required', message: 'Branch type is required.' }],
        licenseStartDate: [{ type: 'required', message: 'License Start Date is required.' }],
        licenseEndDate: [{ type: 'required', message: 'End Date is required.' }],
    }

    const onAddBranch = useCallback(() => {
        setApiCallInProgress(true)
        dispatch(addBranch({ ...props.branchData }))
    }, [props.branchData])

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Add Branch
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(onAddBranch)}>
                    <Form
                        mode='form'
                        formData={props.branchData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem caption='New Branch' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem
                                    dataField='fkCompanyId'
                                    editorType='dxSelectBox'
                                    editorOptions={{
                                        ...props.companyEditorOptions,
                                        readOnly: true,
                                    }}>
                                    <Label text='Company' />
                                </SimpleItem>
                                <SimpleItem dataField='branchName' validationRules={validationRules.branchName}>
                                    <Label text='Branch Name' />
                                </SimpleItem>
                                <SimpleItem dataField='address' />
                                <SimpleItem dataField='phoneNo' editorOptions={phoneEditorOptions}>
                                    <RequiredRule message='Phone number is required.' />
                                    <PatternRule
                                        message='phone number must start with + contain 10 to 15 digits'
                                        pattern={phonePattern}
                                    />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='branchType'
                                    editorType='dxSelectBox'
                                    editorOptions={props.storeEditorOptions}
                                    validationRules={validationRules.branchType}
                                />
                                <GroupItem colCount={2}>
                                    <SimpleItem dataField='transferAutoDispatch' editorType='dxCheckBox'>
                                        <Label text='Transfer Auto Dispatch' />
                                    </SimpleItem>
                                    <SimpleItem dataField='transferAutoReceive' editorType='dxCheckBox'>
                                        <Label text='Transfer Auto Receive' />
                                    </SimpleItem>
                                </GroupItem>
                                <SimpleItem dataField='reportXClientId'>
                                    <Label text='Client Id' />
                                </SimpleItem>
                                <SimpleItem dataField='reportXClientSecret'>
                                    <Label text='Client Secret' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='licenseStartDate'
                                    editorType='dxDateBox'
                                    validationRules={validationRules.licenseStartDate}
                                    editorOptions={{
                                        displayFormat: 'shortDateShortTime',
                                        type: 'datetime',
                                        pickerType: 'calendar',
                                        useMaskBehavior: true,
                                    }}>
                                    <Label text='License Start Date' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='licenseEndDate'
                                    editorType='dxDateBox'
                                    validationRules={validationRules.licenseEndDate}
                                    editorOptions={{
                                        displayFormat: 'shortDateShortTime',
                                        type: 'datetime',
                                        pickerType: 'calendar',
                                        useMaskBehavior: true,
                                    }}>
                                    <Label text='License End Date' />
                                </SimpleItem>
                                <SimpleItem dataField='timezoneId' editorOptions={{ readOnly: true }}>
                                    <Label text='Time Zone' />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddBranchForm
