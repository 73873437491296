import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import RadioGroup from 'devextreme-react/radio-group'
import FreightAmountPaidByTypeService from './FreightAmountPaidByTypeService'

const AddDraftOrderForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })
    const { loading } = useSelector((state) => state.orders)

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/shipments/draftorders'),
    }

    const priorityEntitiesDiscountFixed = [
        { id: true, text: 'Discount Fixed' },
        { id: false, text: 'Discount Percentage' },
    ]

    const onSelectionChangedDiscountFixed = (e) => {
        props.draftOrderData.isDiscountFixed = e.value
    }

    const priorityEntitiesDiscountEach = [
        { id: true, text: 'Discount Each Item' },
        { id: false, text: 'Discount Single Item' },
    ]

    const onSelectionChangedDiscountEach = (e) => {
        props.draftOrderData.isDiscountEach = e.value
    }

    const FreightAmountPaidByTypeEditorOptions = {
        dataSource: FreightAmountPaidByTypeService.getFreightAmountPaidByType(),
        displayExpr: 'FreightAmountPaidByType',
        valueExpr: 'id',
        showClearButton: true,
    }

    const validationRules = {
        orderDate: [{ type: 'required', message: 'Order Date is required.' }],
        supplier: [{ type: 'required', message: 'Supplier is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Add Draft Order
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.addDraftOrder)}>
                    <Form mode='form' formData={props.draftOrderData} showColonAfterLabel={false}>
                        <GroupItem>
                            <GroupItem>
                                <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                <GroupItem caption='Order Details' cssClass='form-group-item'>
                                    <GroupItem cssClass='form-group-item-margin'>
                                        <SimpleItem
                                            editorType='dxDateBox'
                                            dataField='orderDate'
                                            validationRules={validationRules.orderDate}>
                                            <Label text='Order Date' />
                                        </SimpleItem>
                                        <SimpleItem dataField='purchaseOrderNo'>
                                            <Label text='Purchase Order No' />
                                        </SimpleItem>
                                        <SimpleItem dataField='expectedDate' editorType='dxDateBox'>
                                            <Label text='Delivery Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='fkSupplierId'
                                            editorType='dxSelectBox'
                                            validationRules={validationRules.supplier}
                                            editorOptions={props.supplierEditorOptions}>
                                            <Label text='Supplier' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                                <GroupItem>
                                    <GroupItem caption='Freight Charges' cssClass='form-group-item '>
                                        <GroupItem cssClass='form-group-item-margin'>
                                            <SimpleItem dataField='freightAmount' editorType='dxNumberBox'>
                                                <Label text='Freight Amount' />
                                            </SimpleItem>
                                            <SimpleItem dataField='freightAmount' editorType='dxNumberBox'>
                                                <Label text='Bity Number' />
                                            </SimpleItem>
                                            <SimpleItem
                                                editorType='dxSelectBox'
                                                dataField='FreightAmountPaidByType'
                                                editorOptions={FreightAmountPaidByTypeEditorOptions}
                                            />
                                            <SimpleItem dataField='freightComments'>
                                                <Label text='Bity Provider' />
                                            </SimpleItem>
                                        </GroupItem>
                                    </GroupItem>
                                    <GroupItem caption='Configuration' cssClass='form-group-item '>
                                        <GroupItem cssClass='form-group-item-margin'>
                                            <RadioGroup
                                                items={priorityEntitiesDiscountFixed}
                                                valueExpr='id'
                                                displayExpr='text'
                                                layout='horizontal'
                                                defaultValue={props.draftOrderData.isDiscountFixed}
                                                onValueChanged={onSelectionChangedDiscountFixed}
                                            />
                                            <RadioGroup
                                                items={priorityEntitiesDiscountEach}
                                                valueExpr='id'
                                                displayExpr='text'
                                                layout='horizontal'
                                                defaultValue={props.draftOrderData.isDiscountEach}
                                                onValueChanged={onSelectionChangedDiscountEach}
                                            />
                                        </GroupItem>
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddDraftOrderForm
