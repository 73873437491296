import React, { useState, useMemo, useEffect } from 'react'
import DataSource from 'devextreme/data/data_source'
import { SelectBox } from 'devextreme-react/select-box'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import { getBranchUserLookupList } from 'app/redux/store/features/users'
import useDebouncedInput from 'app/pages/reports/hooks/useDebouncedInput'
import { useDispatch, useSelector } from 'react-redux'
import ArrayStore from 'devextreme/data/array_store'
import transactionStatusService from './transactionStatusServices'
import utilServices from 'app/services/util-services'

const OrderListReportFilter = ({ mutation }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getBranchUserLookupList(''))
    }, [])

    const { branchUsers } = useSelector((state) => state.users)

    const branchUserLookupStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'userId',
                    data: branchUsers.data,
                    totalCount: branchUsers.length,
                }),
                reshapeOnPush: true,
            }),
        [branchUsers]
    )

    let initialValues = {
        startDate: DateUtils.getUtcStartDate(10).toISOString().slice(0, 16),
        endDate: DateUtils.getUtcEndDate().toISOString().slice(0, 16),
        orderNo: '',
        supplierName: '',
        comments: '',
    }

    const [filter, setFilter] = useState({ CheckoutById: null })

    const [transactionStatusSelection, setTransactionStatusSelection] = useState(null)

    const onFormSubmit = (startDate, endDate, orderNo, supplierName, comments) => {
        mutation.mutate({
            startDate: startDate.toFilterStartDateTime(),
            endDate: endDate.toFilterEndDateTime(),
            orderNo: orderNo,
            supplierName: supplierName,
            comments: comments,
            transStatusId: utilServices.isNullOrUndefined(transactionStatusSelection)
                ? null
                : transactionStatusSelection.id,

            ...filter,
        })
    }

    const handleClear = () => {
        setFilter({ CheckoutById: null })
        setTransactionStatusSelection(null)
    }

    const onFilterSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        onFormSubmit(data.startDate, data.endDate, data.orderNo, data.supplierName, data.comments)

        setSubmitting(false)
    }

    const {
        value: CheckOutByValue,
        debouncedValue: debouncedCheckOutByValue,
        onChange: onCheckOutBySearch,
    } = useDebouncedInput('', 300)

    useEffect(() => {
        dispatch(getBranchUserLookupList(CheckOutByValue))
    }, [debouncedCheckOutByValue])

    const onCheckedOutByChanged = (value) => {
        setFilter({ ...filter, CheckoutById: value?.userId })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ display: '', borderRadius: 0 }}>
                        <Divider />
                        <Div
                            className='report-filter-parent'
                            style={{
                                display: 'flex',
                                margin: '0% 1.5% 1.5% 1.5%',
                                flexWrap: 'wrap',
                            }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='startDate'
                                        type='datetime-local'
                                        defaultValue={initialValues.startDate}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='endDate'
                                        type='datetime-local'
                                        defaultValue={initialValues.endDate}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Supplier Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='supplierName' />
                                </Div>
                            </Div>

                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Order No</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='orderNo' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Comments</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='comments' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Checkout By</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={branchUserLookupStore}
                                        displayExpr={'firstName'}
                                        value={filter.CheckoutById}
                                        searchEnabled={true}
                                        onValueChange={onCheckedOutByChanged}
                                        onInput={onCheckOutBySearch}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Transaction Status</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={transactionStatusService.getTransactionStatus()}
                                        displayExpr={'name'}
                                        searchEnabled={true}
                                        onValueChange={setTransactionStatusSelection}
                                        placeholder='All'
                                        showClearButton={true}
                                        value={transactionStatusSelection}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default OrderListReportFilter
