import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services.js'
import { TabContext, TabPanel } from '@mui/lab'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import { getOrderById, selectItemById } from 'app/redux/store/features/orders'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import UpdateDraftOrderForm from './UpdateDraftOrderForm.js'
import OrderItemGrid from './OrderItemGrid/OrderItemGrid.js'

const UpdateDraftOrder = () => {
    const [tabTitle, setTabTitle] = React.useState('details')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { orderId } = state // Destructuring orderId as orderId
    const { orders } = useSelector((state) => state.orders)
    const order = selectItemById(orders, orderId)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(order.orderId))
            dispatch(getOrderById({ orderId: orderId, onlineShipment: true }))
    }, [])

    const onTabSelectionChanged = useCallback((e, newValue) => {
        setTabTitle(newValue)
    }, [])

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Order
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={tabTitle}>
                    <TabList
                        sx={{ bgcolor: 'background.default', m: 2, mb: 2, marginLeft: '1.4%', marginRight: '1.4%' }}
                        onChange={onTabSelectionChanged}>
                        <Tab label={'Details'} value={'details'} sx={{ width: '160px' }} />
                        <Tab label={'Items'} value={'items'} sx={{ width: '160px' }} />
                    </TabList>
                    <TabPanel value='details'>
                        <UpdateDraftOrderForm order={order} />
                    </TabPanel>
                    <TabPanel value='items'>
                        <OrderItemGrid orderId={orderId} inventoryItems={order.inventoryItems} />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateDraftOrder
