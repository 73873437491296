import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateTransferItems } from 'app/redux/store/features/transfers'
import TransferItemAddHeader from './components/TransferItemAddHeader'
import DataSource from 'devextreme/data/data_source'
import DataGrid, { Column, Editing, Paging } from 'devextreme-react/data-grid'
import formatCurrency from '../../../../../../../utils/components/formatCurrency/formatCurrency'
import { defaultTransferItem } from './components/defaultTransferItem'
import uuid from 'draft-js/lib/uuid'
import LocalStore from 'devextreme/data/local_store'

const TransferItemGrid = (props) => {
    const dispatch = useDispatch()
    const gridRef = useRef(null)

    useEffect(() => {
        gridRef.current.instance.refresh(true)
        gridRef.current.instance.cancelEditData()
    }, [props.inventoryItems])

    const onClickSave = useCallback(async () => {
        await gridRef.current.instance.saveEditData()
    }, [])

    const onClickAdd = useCallback(async (data) => {
        let newInventory = {
            ...defaultTransferItem,
            transferInventoryItemId: uuid(),
            cartName: data.inventory.inventoryName,
            commends: null,
            displayOrder: 1,
            requestedQty: data.transferQty,
            fkTransferInventoryId: props.transferInventoryId,
            fkSenderInvClassId: data.inventory.inventoryId,
            senderPurchasePriceIsAveragePrice: data.inventory.senderPurchasePriceIsAveragePrice,
            senderPurchasePrice: data.inventory.purchasePrice,
            senderSalePriceIsAveragePrice: data.inventory.senderSalePriceIsAveragePrice,
            senderSalePrice: data.inventory.salePrice,
            senderInventoryProfitMargin: data.inventory.profitMargin ?? 0,
            subTotal: 1 * (data.inventory.purchasePrice ?? 0),
        }

        gridRef.current.instance.addRow()
        gridRef.current.instance.cellValue(0, 'transferInventoryItemId', newInventory.transferInventoryItemId)
        gridRef.current.instance.cellValue(0, 'cartName', newInventory.cartName)
        gridRef.current.instance.cellValue(0, 'displayOrder', newInventory.displayOrder)
        gridRef.current.instance.cellValue(0, 'requestedQty', newInventory.requestedQty)
        gridRef.current.instance.cellValue(0, 'fkTransferInventoryId', newInventory.fkTransferInventoryId)
        gridRef.current.instance.cellValue(0, 'fkSenderInvClassId', newInventory.fkSenderInvClassId)
        gridRef.current.instance.cellValue(
            0,
            'senderPurchasePriceIsAveragePrice',
            newInventory.senderPurchasePriceIsAveragePrice
        )
        gridRef.current.instance.cellValue(0, 'senderPurchasePrice', newInventory.senderPurchasePrice)
        gridRef.current.instance.cellValue(
            0,
            'senderSalePriceIsAveragePrice',
            newInventory.senderSalePriceIsAveragePrice
        )
        gridRef.current.instance.cellValue(0, 'senderSalePrice', newInventory.senderSalePrice)
        gridRef.current.instance.cellValue(0, 'senderInventoryProfitMargin', newInventory.senderInventoryProfitMargin)
        gridRef.current.instance.cellValue(0, 'subTotal', newInventory.subTotal)
        gridRef.current.instance.cellValue(0, 'comments', newInventory.comments)
    }, [])

    const TransferItemStore = useMemo(() => {
        return new LocalStore({
            key: 'transferInventoryItemId',
            data: props.inventoryItems?.data ?? [],
            name: 'transferItems',
        })
    }, [props.inventoryItems])

    const TransferItemDataSource = useMemo(() => {
        return new DataSource({
            store: TransferItemStore,
            reshapeOnPush: true,
        })
    }, [])

    async function sendBatchRequest(changes) {
        let transfer = {
            transferInventoryId: props.transferInventoryId,
            inventoryItems: [],
            removeItems: [],
        }
        let gridRows = await TransferItemStore.load()
        changes.map((c, i) => {
            if (c.type === 'remove') {
                transfer.removeItems.push({
                    transferInventoryItemId: c.key,
                })
                return
            }
            let row = {}
            if (c.type === 'update') {
                row = gridRows.find((entry) => entry.transferInventoryItemId === c.key)
            }
            transfer.inventoryItems.push({
                transferInventoryItemId: c?.key?.replace('_DX_KEY_', '') ?? row?.transferInventoryItemId,
                cartName: c?.data?.cartName ?? row?.cartName,
                comments: c?.data?.comments ?? row?.comments,

                displayOrder: c?.data?.displayOrder ?? row?.displayOrder,
                requestedQty: c?.data?.requestedQty ?? row?.requestedQty,
                fkTransferInventoryId: props.transferInventoryId,

                fkSenderInvClassId: c?.data?.fkSenderInvClassId ?? row?.fkSenderInvClassId,

                senderPurchasePriceIsAveragePrice:
                    c?.data?.senderPurchasePriceIsAveragePrice ?? row?.senderPurchasePriceIsAveragePrice,
                senderPurchasePrice: c?.data?.senderPurchasePrice ?? row?.senderPurchasePrice,

                senderSalePriceIsAveragePrice:
                    c?.data?.senderSalePriceIsAveragePrice ?? row?.senderSalePriceIsAveragePrice,
                senderSalePrice: c?.data?.senderSalePrice ?? row?.senderSalePrice,
                senderInventoryProfitMargin: c?.data?.senderInventoryProfitMargin ?? row?.senderInventoryProfitMargin,
                subTotal:
                    (c?.data?.requestedQty ?? row?.requestedQty ?? 1) *
                    (c?.data?.senderPurchasePrice ?? row?.senderPurchasePrice ?? 0),
            })
        })
        dispatch(updateTransferItems(transfer))
    }
    async function processBatchRequest(changes, component) {
        await sendBatchRequest(changes)
        // await component.refresh(true)
        // component.cancelEditData()
    }
    const onSaving = (e) => {
        e.cancel = true
        if (e.changes.length) {
            e.promise = processBatchRequest(e.changes, e.component)
        }
    }

    const onEditingStart = useCallback((e) => {
        e.cancel = e.data.Position === 'CEO'
    }, [])

    const saveEditData = useCallback((e) => {
        gridRef.current.instance.saveEditData()
    }, [])

    return (
        <Grid sx={{ marginTop: '-24px', marginBottom: '-24px' }}>
            <Grid>
                <TransferItemAddHeader
                    onClickAdd={onClickAdd}
                    onClickSave={onClickSave}
                    fkSenderBranchId={props.fkSenderBranchId}
                    transferStatus={props.transferStatus}
                    //setFilterTransfer={setFilterTransfer}
                />
            </Grid>
            <Grid>
                <React.Fragment>
                    <DataGrid
                        ref={gridRef}
                        height={780}
                        dataSource={TransferItemDataSource}
                        remoteOperations={true}
                        onSaving={onSaving}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}>
                        <Paging enabled={false} />
                        <Editing
                            mode='batch'
                            allowUpdating={props.transferStatus < 20}
                            allowDeleting={props.transferStatus < 20}
                            useIcons={true}
                            startEditAction={'dblClick'}
                        />
                        <Column dataField='fkSenderInvClassId' visible={false} />
                        <Column dataField='cartName' caption='Inventory Name' />
                        <Column
                            dataField='requestedQty'
                            caption='Transfer Qty'
                            dataType='number'
                            alignment='center'
                            width='8%'
                        />
                        <Column
                            dataField='senderPurchasePriceIsAveragePrice'
                            caption='Average Purchase Price'
                            dataType='boolean'
                            alignment='center'
                        />
                        <Column
                            dataField='senderPurchasePrice'
                            caption='Purchase Price'
                            dataType='number'
                            format={(data) => formatCurrency(data)}
                            alignment='center'
                        />
                        <Column
                            dataField='senderSalePriceIsAveragePrice'
                            caption='Avergage Sale Price'
                            dataType='boolean'
                            alignment='center'
                        />
                        <Column
                            dataField='senderSalePrice'
                            caption='Sale Price'
                            dataType='number'
                            format={(data) => formatCurrency(data)}
                            alignment='center'
                        />
                        <Column
                            dataField='senderInventoryProfitMargin'
                            caption='Profit Margin'
                            dataType='number'
                            format={(data) => formatCurrency(data)}
                            alignment='center'
                        />
                        <Column
                            dataField='subTotal'
                            caption='Sub Total'
                            dataType='number'
                            format={(data) => formatCurrency(data)}
                            alignment='center'
                            allowEditing={false}
                        />
                        <Column dataField='comments' caption='Comments' />
                    </DataGrid>
                </React.Fragment>
            </Grid>
        </Grid>
    )
}
export default TransferItemGrid
