import React, { useCallback, useMemo, useState, useRef } from 'react'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import { DropDownBox } from 'devextreme-react'
import { useSnackbar } from 'notistack'
import DataGrid, { FilterRow, Paging, Selection } from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from '../../../../../../../../services/util-services'
import inventoryService from '../../../../../../../../services/pages/store/inventory/inventoryServices'
import { useNavigate } from 'react-router-dom'
import { buildFilter } from '../../../../../../../../utils/appHelpers'
import useApp from '../../../../../../../../hooks/useApp'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'

const TransferItemAddHeader = ({ onClickAdd, onClickSave, fkSenderBranchId, transferStatus }) => {
    const navigate = useNavigate()
    const inventoryDropDownRef = useRef()
    const { enqueueSnackbar } = useSnackbar()
    const { branchSelection } = useApp()

    let initialValues = {
        transferqty: '',
    }

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const ownerLabel = { 'aria-label': 'Owner' }
    const gridColumns = [
        { dataField: 'barcode' },
        { dataField: 'inventoryName' },
        { dataField: 'totalStock', allowFiltering: false },
    ]
    const gridBoxDisplayExpr = useCallback((item) => {
        return item && `${item.barcode}, ${item.inventoryName}, ${item.totalStock}`
    }, [])

    const inventoryLookupList = useCallback(async (filter) => {
        try {
            return await inventoryService.getInventoryList(filter)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const inventoryLookupById = useCallback(async (key) => {
        try {
            return await inventoryService.getInventoryById({ invClassId: key })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const inventoryLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    loadMode: 'processed',
                    key: 'inventoryId',
                    load: async function (loadOptions) {
                        let searchTerm = { skip: 0, take: 10 }
                        if (loadOptions?.filter) {
                            const filter = buildFilter(loadOptions.filter)
                            searchTerm = { ...searchTerm, ...filter }
                        }
                        return await inventoryLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (utilServices.isNullOrUndefined(key) || key === '') {
                            return {}
                        }
                        return await inventoryLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const [gridBoxValue, setGridBoxValue] = useState([''])
    const [isGridBoxOpened, setIsGridBoxOpened] = useState(false)
    const dataGridOnSelectionChanged = useCallback((e) => {
        setGridBoxValue(e.selectedRowKeys)
        setIsGridBoxOpened(false)
    }, [])

    const dataGridRender = useCallback(
        () => (
            <DataGrid
                dataSource={inventoryLookupStore}
                columns={gridColumns}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                showBorders={true}
                selectedRowKeys={gridBoxValue}
                onSelectionChanged={dataGridOnSelectionChanged}
                height='100%'
                remoteOperations={true}>
                <Selection mode='single' />
                <Paging enabled={false} pageSize={10} />
                <FilterRow visible={true} applyFilter='auto' />
            </DataGrid>
        ),
        [gridBoxValue, dataGridOnSelectionChanged]
    )
    const syncDataGridSelection = useCallback((e) => {
        setGridBoxValue(e.value)
        if (inventoryDropDownRef.current?.instance) {
            inventoryDropDownRef.current.instance.focus()
        }
    }, [])

    const onGridBoxOpened = useCallback((e) => {
        if (e.name === 'opened') {
            setIsGridBoxOpened(e.value)
        }
    }, [])

    const onFormSubmit = async (values, { resetForm }) => {
        if (gridBoxValue && gridBoxValue.length <= 0) {
            enqueueSnackbar('Please select an inventory and try again', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else if (!values.transferqty) {
            enqueueSnackbar('Please enter Transfer Qty and try again', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else if (branchSelection.branchId !== fkSenderBranchId) {
            enqueueSnackbar('Only sender branch can add items, please change and try again', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else if (transferStatus >= 20) {
            enqueueSnackbar('We cannot modify an approved transfer', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            const inventory = await inventoryLookupById(gridBoxValue)
            const transferQty = values.transferqty
            onClickAdd({
                inventory,
                transferQty,
            })
            resetForm()
            setGridBoxValue([''])
        }
    }

    const onFilterSubmit = async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true)
        await onFormSubmit(values, formikHelpers)
        formikHelpers.setSubmitting(false)
    }

    const handleSave = (e) => {
        onClickSave()
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onSubmit={onFilterSubmit}
            //onReset={onFilterSubmit}
        >
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ borderRadius: 0 }}>
                        <Div style={{ display: 'flex', margin: 11 }}>
                            <Div sx={{ mr: 3, ml: 4, width: 480 }}>
                                <Div>
                                    <Typography>Inventory</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <DropDownBox
                                        ref={inventoryDropDownRef}
                                        value={gridBoxValue}
                                        opened={isGridBoxOpened}
                                        valueExpr='inventoryId'
                                        deferRendering={false}
                                        inputAttr={{ 'aria-label': 'Owner' }}
                                        displayExpr={gridBoxDisplayExpr}
                                        placeholder='Select a value...'
                                        showClearButton={true}
                                        dataSource={inventoryLookupStore}
                                        onValueChanged={syncDataGridSelection}
                                        onOptionChanged={onGridBoxOpened}
                                        contentRender={dataGridRender}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, width: 200 }}>
                                <Div>
                                    <Typography>Transfer Qty</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 200 }}
                                        size='small'
                                        name='transferqty'
                                        type='number'
                                        inputProps={{ min: 1 }}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 3.0, width: 180 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        sx={{ width: 100, height: 35 }}
                                        size={'small'}
                                        type='submit'
                                        variant={'outlined'}
                                        loading={isSubmitting || onClickAdd.isLoading}>
                                        <AddIcon sx={{ fontSize: 18, mr: 1, ml: -2 }} /> Add
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}
export default TransferItemAddHeader
