import React from 'react'
import Page from '@jumbo/shared/Page'
import ReportApp from '../pages/reports/ReportApp'

const reportRoutes = [
    {
        path: ['/reports', '/reports/:category/:id'],
        element: <Page component={ReportApp} />,
    },
]

export default reportRoutes
