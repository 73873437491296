import React, { useCallback } from 'react'
import { ListItemText, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { useNavigate } from 'react-router-dom'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import Span from '@jumbo/shared/Span'

const RightInfoPanelItem = ({ item, min }) => {
    const navigate = useNavigate()

    const handleTxnNumberClick = useCallback(
        ({ item }) => {
            navigate(`/store/sales/all-transaction/details/${item.posTxId}`, { state: { posTxId: item.posTxId } })
        },
        [navigate]
    )

    return (
        <ListItemButton
            key={item.id}
            component='li'
            sx={{ p: (theme) => theme.spacing(1, 3) }}
            onClick={() => handleTxnNumberClick({ item })}>
            <MonetizationOnIcon sx={{ mr: 2 }} />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                    minWidth: '120px',
                }}
                primary={
                    <Typography variant='h5' sx={{ width: min ? '120px' : '120px' }} mb={0.5} color='text.primary'>
                        {item.txnNumber}
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography
                        variant='h6'
                        mb={0.5}
                        color='text.primary'
                        sx={{ width: min ? '120px' : '120px', wordWrap: min ? 'break-word' : 'normal' }}>
                        <Span
                            sx={{
                                mr: 1,
                                whiteSpace: 'normal',
                            }}>
                            {item.customerFirstName + ' ' + item.customerLastName}
                        </Span>
                    </Typography>
                }
            />
            <ListItemText
                primary={
                    <Typography
                        variant='body1'
                        sx={{ fontSize: 13, width: min ? '100px' : '170px' }}
                        color='text.primary'>
                        <Span
                            sx={{
                                mr: 1,
                                whiteSpace: 'normal',
                            }}>
                            {formatDateTime(item.txnStartDate)}
                        </Span>{' '}
                    </Typography>
                }
                sx={{
                    flexBasis: '30%',
                    marginRight: min ? 0 : 12,
                }}
            />
            <ListItemText
                primary={
                    <Typography
                        variant='body1'
                        sx={{ fontSize: 13, width: min ? '100px' : '100px' }}
                        color='text.primary'>
                        <Span>{formatCurrency(item.txnTotal)}</Span>{' '}
                    </Typography>
                }
                secondary={'Total'}
                sx={{
                    flexBasis: '30%',
                }}
            />
            <ListItemText
                sx={{ alignSelf: 'self-start', marginRight: 1 }}
                primary={
                    <Typography
                        variant='body1'
                        component={'span'}
                        color='text.primary'
                        sx={{ width: min ? '100px' : '100px' }}>
                        {formatCurrency(item.totalPayment)}
                    </Typography>
                }
                secondary={'Overdue'}
            />
        </ListItemButton>
    )
}

export default RightInfoPanelItem
