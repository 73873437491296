import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddInventoryForm from './AddInventoryForm'
import { defaultInventory } from './defaultInventory'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import inventoryService from 'app/services/pages/store/inventory/inventoryServices'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import supplierService from 'app/services/shipments/supplier/supplierServices'
import DiscountModeServices from './DiscountModeServices'
import utilServices from 'app/services/util-services'

const AddInventory = () => {
    const navigate = useNavigate()
    const [inventoryData] = useState({ ...defaultInventory })
    const { enqueueSnackbar } = useSnackbar()

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const inventoryTypeLookupList = useCallback(async (searchTerm) => {
        try {
            return await inventoryService.getInventoryTypeLookupList({ typeName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const inventoryTypeLookupById = useCallback(async (key) => {
        try {
            return await inventoryService.getInventoryTypesLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const InventoryTypeStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'invTypeId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await inventoryTypeLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await inventoryTypeLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const inventoryTypeEditorOptions = {
        dataSource: InventoryTypeStore,
        displayExpr: 'typeName',
        valueExpr: 'invTypeId',
        searchEnabled: true,
        showClearButton: true,
    }

    const ChartAccountLookupList = useCallback(async (searchTerm) => {
        try {
            return await inventoryService.getChartAccountLookupList({ accountName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartAccountLookupById = useCallback(async (key) => {
        try {
            return await inventoryService.getChartAccountLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartAccountStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'chartAccountId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await ChartAccountLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await ChartAccountLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const chartAccountEditorOptions = {
        dataSource: ChartAccountStore,
        displayExpr: 'accountName',
        valueExpr: 'chartAccountId',
        searchEnabled: true,
        showClearButton: true,
    }

    const UOMLookupList = useCallback(async (searchTerm) => {
        try {
            return await inventoryService.getUOMLookupList({ uomName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const UOMLookupById = useCallback(async (key) => {
        try {
            return await inventoryService.getUOMLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const UomStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'uomId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await UOMLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await UOMLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const UnitOfMeasureEditorOptions = {
        dataSource: UomStore,
        displayExpr: 'uomName',
        valueExpr: 'uomId',
        searchEnabled: true,
        showClearButton: true,
    }

    const SupplierLookupList = useCallback(async (searchTerm) => {
        try {
            return await supplierService.getSupplierLookupList({ supplierName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const SupplierLookupById = useCallback(async (key) => {
        try {
            return await supplierService.getSupplierById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const SupplierLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'supplierId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await SupplierLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await SupplierLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const multiSupplierEditorOptions = {
        dataSource: SupplierLookupStore,
        displayExpr: 'supplierName',
        valueExpr: 'supplierId',
        searchEnabled: true,
        showClearButton: true,
    }

    const ChartTaxLookupList = useCallback(async (searchTerm) => {
        try {
            return await inventoryService.getChartTaxLookupList({ taxName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartTaxLookupById = useCallback(async (key) => {
        try {
            return await inventoryService.getChartTaxLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartTaxStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'chartTaxId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await ChartTaxLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return await utilServices.emptyList()

                        return ChartTaxLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const chartTaxEditorOptions = {
        dataSource: ChartTaxStore,
        displayExpr: 'taxName',
        valueExpr: 'chartTaxId',
        searchEnabled: true,
        showClearButton: true,
    }

    const DiscountModeEditorOptions = {
        dataSource: DiscountModeServices.getDiscountMode(),
        displayExpr: 'discountMode',
        valueExpr: 'id',
        showClearButton: true,
    }

    return (
        <React.Fragment>
            <AddInventoryForm
                inventoryData={inventoryData}
                chartAccountEditorOptions={chartAccountEditorOptions}
                chartTaxEditorOptions={chartTaxEditorOptions}
                inventoryTypeEditorOptions={inventoryTypeEditorOptions}
                UnitOfMeasureEditorOptions={UnitOfMeasureEditorOptions}
                multiSupplierEditorOptions={multiSupplierEditorOptions}
                DiscountModeEditorOptions={DiscountModeEditorOptions}
            />
        </React.Fragment>
    )
}

export default AddInventory
