import React from 'react'
import { Stack, Typography } from '@mui/material'
import CardIconText from '@jumbo/shared/CardIconText'
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone'
import PointOfSaleTwoToneIcon from '@mui/icons-material/PointOfSaleTwoTone'
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone'
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { formattedDate } from 'app/utils/components/FormatDateTime/FormatDateTime'

const HeaderCardList = ({ item }) => {
    const getCardStyle = (icon) => {
        switch (icon) {
            case 'sales':
                return { color: '#00A073', iconComponent: <PointOfSaleTwoToneIcon /> }
            case 'payment':
                return { color: '#DF295E', iconComponent: <LocalAtmTwoToneIcon /> }
            case 'cash':
                return { color: '#008598', iconComponent: <MonetizationOnTwoToneIcon /> }
            case 'credit':
                return { color: '#BB6900', iconComponent: <CreditCardTwoToneIcon /> }
            default:
                return { color: '#BB6901', iconComponent: <PointOfSaleTwoToneIcon /> }
        }
    }

    return (
        <CardIconText
            icon={getCardStyle(item.icon).iconComponent}
            color={getCardStyle(item.icon).color}
            title={
                <Typography variant='h4' fontFamily='Lobster, cursive' color={getCardStyle(item.icon).color}>
                    {item.title}
                </Typography>
            }
            subTitle={
                <React.Fragment>
                    <Stack direction={'row'} spacing={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography variant={'h6'}>{formattedDate(item.from)}</Typography>
                        <Typography variant='h6' color={getCardStyle(item.icon).color}>
                            {formatCurrency(item.totalValue)}
                        </Typography>
                    </Stack>
                </React.Fragment>
            }
            hideArrow={true}
            variant={'contained'}
        />
    )
}

export default HeaderCardList
