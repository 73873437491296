import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, Link, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import { deleteTransfer, getTransferById } from 'app/redux/store/features/transfers'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import useJumboTheme from '../../../../../../../@jumbo/hooks/useJumboTheme'

const TransferInventoryData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const { transfers, loading, error } = useSelector((state) => state.transfers)
    const dateColumns = ['transferRequiredDate', 'createdDate', 'updatedDate']
    const [sorting, setSorting] = useState([{ columnName: 'createdDate', direction: 'desc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getTransferById({ transferInventoryId: id }))
        navigate(`/company/transfers/inventories/details/${id}`, { state: { transferInventoryId: id } })
    }

    const onNavigatingToEdit = (id) => {
        dispatch(getTransferById({ transferInventoryId: id }))
        navigate(`/company/transfers/inventories/edit/${id}`, { state: { transferInventoryId: id } })
    }

    const hideDialogAndRefreshTransferList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Transfer data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onRemoveTransfer = async (transferInventoryId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteTransfer({ transferInventoryId: transferInventoryId }))
                hideDialogAndRefreshTransferList()
            },
            onNo: hideDialog,
        })
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.transferInventoryId)}
                sx={{
                    '&:hover': {
                        color: 'action.active', // Change this to the color you want on hover
                        cursor: 'pointer',
                    },
                }}>
                {row.transferNo}
            </Link>
        )
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.transferInventoryId)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        // Conditionally render the action icons based on the transferStatusName
        if (row.transferStatusName === 'Transfer Draft Created') {
            return (
                <div>
                    <IconButton onClick={() => onNavigatingToDetail(row.transferInventoryId)}>
                        <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                    </IconButton>
                    <IconButton onClick={() => onNavigatingToEdit(row.transferInventoryId)}>
                        <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                    </IconButton>
                    <IconButton onClick={() => onRemoveTransfer(row.transferInventoryId)}>
                        <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                    </IconButton>
                </div>
            )
        } else {
            // If the status is not "Transfer Draft Created", render only the VisibilityIcon and EditIcon
            return (
                <div>
                    <IconButton onClick={() => onNavigatingToDetail(row.transferInventoryId)}>
                        <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                    </IconButton>
                    <IconButton onClick={() => onNavigatingToEdit(row.transferInventoryId)}>
                        <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                    </IconButton>
                </div>
            )
        }
    }

    const columns = [
        { name: 'transferNo', title: 'Transfer No', getCellValue: renderLinkCell },
        { name: 'senderBranchName', title: 'Sender Branch' },
        { name: 'receivingBranchName', title: 'Receiving Branch' },
        { name: 'transferRequiredDate', title: 'Date' },
        { name: 'transferStatusName', title: 'Status' },
        { name: 'comments', title: 'Comments' },
        { name: 'createdDate', title: 'Date Created' },
        { name: 'updatedDate', title: 'Date Updated' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'transferNo', width: min ? '120px' : '9%', minWidth: '120px', wordWrapEnabled: true },
            {
                columnName: 'senderBranchName',
                wordWrapEnabled: true,
                width: min ? '100px' : '10%',
                minWidth: '100px',
            },
            {
                columnName: 'receivingBranchName',
                wordWrapEnabled: true,
                width: min ? '110px' : '11%',
                minWidth: '110px',
            },
            {
                columnName: 'transferRequiredDate',
                width: min ? '110px' : '13%',
                minWidth: '110px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'transferStatusName',
                width: min ? '110px' : '11%',
                minWidth: '110px',
                wordWrapEnabled: true,
            },
            { columnName: 'comments', width: min ? '130px' : '13%', minWidth: '130px', wordWrapEnabled: true },
            { columnName: 'createdDate', width: min ? '110px' : '13%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'updatedDate', width: min ? '110px' : '13%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'Action', align: 'center', width: min ? '90px' : '7%', minWidth: '90px' },
        ],
        [min]
    )
    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [
        { columnName: 'Action', sortingEnabled: false },
        { columnName: 'transferStatusName', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={transfers.data} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={transfers.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default TransferInventoryData
