import React from 'react'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { Card, CardContent, ListItem, ListItemText, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import Span from '@jumbo/shared/Span'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import StoreIcon from '@mui/icons-material/Store'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import StorefrontIcon from '@mui/icons-material/Storefront'
import MoneyIcon from '@mui/icons-material/Money'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const LeftInfoCard = ({ order, onlineShipment }) => {
    return (
        <Grid>
            <Card sx={{ height: 310 }}>
                <CardContent sx={{ justifyContent: 'center' }}>
                    <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item item xs={12} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <List sx={{ width: '100%', color: 'text.primary' }}>
                                <ListItem>
                                    <ListItemText disablePadding>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <StoreIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            Purchase Order No:
                                            <Span sx={{ color: 'secondary.dark' }}> {order.purchaseOrderNo} </Span>{' '}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ mt: -1 }}>
                                    <ListItemText disablePadding>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <CalendarViewMonthIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            Delivery Date:
                                            <Span sx={{ color: 'secondary.dark' }}>
                                                <Span>{`${formatDateTime(order?.expectedDate ?? '')}`}</Span>
                                            </Span>
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ mt: -1 }}>
                                    <ListItemText disablePadding>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <StorefrontIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                            {order.supplierName}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        {onlineShipment ? (
                            <Grid item sx={{ width: '100%', display: 'flex', alignItems: 'center', ml: 3 }}>
                                <List sx={{ width: '100%' }}>
                                    <ListItemText>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <MoneyIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            Additional Tax:
                                            <Span sx={{ color: 'secondary.dark' }}>
                                                {' ' + formatCurrency(order.additionalTaxAmount)}
                                            </Span>
                                        </Typography>
                                    </ListItemText>
                                    <Divider sx={{ mb: 2 }} />
                                    <ListItemText>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <MoneyIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            Advance Tax:
                                            <Span sx={{ color: 'secondary.dark' }}>
                                                {' ' + formatCurrency(order.advanceTaxAmount)}
                                            </Span>
                                        </Typography>
                                    </ListItemText>
                                    <Divider sx={{ mb: 2 }} />
                                    <ListItemText>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <MoneyIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                            Freight Amount:
                                            <Span sx={{ color: 'secondary.dark' }}>
                                                {' ' + formatCurrency(order.freightAmount)}
                                            </Span>
                                        </Typography>
                                    </ListItemText>
                                    <Divider sx={{ mb: 2 }} />
                                </List>
                            </Grid>
                        ) : (
                            <>
                                <Grid
                                    item
                                    sx={{
                                        width: '45%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginRight: '5%',
                                    }}></Grid>
                            </>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default LeftInfoCard
