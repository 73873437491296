import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    transactions: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getTransactions: (state, action) => {
            state.transactions = action.payload
            state.loading = false
            state.refreshing = false
        },
        getExistingTransactionById: (state, action) => {
            const index = state.transactions.data.findIndex(
                (transaction) => transaction.posTxId === action.payload.posTxId
            )
            if (index >= 0) state.transactions.data[index] = action.payload
            else {
                state.transactions.data.push(action.payload)
                state.transactions.totalCount++
            }
            state.loading = false
        },
        getTransactionItems: (state, action) => {
            if (action.payload.data.length > 0) {
                const index = state.transactions.data.findIndex(
                    (transaction) => transaction.posTxId === action.payload.data[0].posTxId
                )
                if (index >= 0) {
                    state.transactions.data[index].txnItems = action.payload
                }
                //We cannot add item if the transaction does not exist
                else {
                    //     state.transactions.data.push(action.payload)
                    //     state.transactions.totalCount++
                }
            }
            state.loading = false
        },
        getTransactionPayments: (state, action) => {
            if (action.payload.data.length > 0) {
                const index = state.transactions.data.findIndex(
                    (transaction) => transaction.posTxId === action.payload.data[0].posTxId
                )
                if (index >= 0) {
                    state.transactions.data[index].txnPayments = action.payload
                } else {
                    // Need to consider creating a transaction and adding payments
                    // state.transactions.data.push(action.payload)
                    // state.transactions.totalCount++
                }
            }
            state.loading = false
        },

        // addNewTransaction: (state, action) => {
        //     state.transactions.data.push(action.payload)
        //     state.transactions.totalCount++
        //     state.loading = false
        // },
        // updateExistingTransaction: (state, action) => {
        //     const index = state.transactions.data.findIndex(
        //         (transaction) => transaction.posTxId === action.payload.posTxId
        //     )
        //     if (index >= 0) state.transactions.data[index] = action.payload
        //     state.loading = false
        // },
        // removeTransaction: (state, action) => {
        //     const index = state.transactions.data.findIndex(
        //         (transaction) => transaction.posTxId === action.payload.posTxId
        //     )
        //     if (index >= 0) {
        //         state.transactions.data.splice(index, 1)
        //         state.transactions.totalCount--
        //     }
        //     state.loading = false
        // },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.posTxId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    apiRequested,
    refreshRequested,
    apiRequestedFailed,
    getTransactions,
    getExistingTransactionById,
    getTransactionItems,
    getTransactionPayments,
    // addNewTransaction,
    // updateExistingTransaction,
    // removeTransaction,
} = transactionSlice.actions
export default transactionSlice.reducer

export const getTransactionList = (filter, refresh = false) => {
    let url = `/sales/Transaction/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getTransactions.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransactionById = (filter) => {
    let url = `/sales/Transaction/${filter.posTxId}?onlineTxn=${filter.onlineTxn}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingTransactionById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransactionItemsList = (filter) => {
    let url = `/sales/Transaction/${filter.posTxId}/items?onlineTxn=${filter.onlineTxn}`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTransactionItems.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransactionPaymentsList = (filter) => {
    let url = `/sales/Transaction/${filter.posTxId}/payments?onlineTxn=${filter.onlineTxn}`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTransactionPayments.type,
        onError: apiRequestedFailed.type,
    })
}

// export const addTransaction = (transaction) => {
//     let url = `/company/transaction/add`
//     return apiCallBegan({
//         url: url,
//         method: 'post',
//         data: transaction,
//         onStart: apiRequested.type,
//         onSuccess: addNewTransaction.type,
//         onError: apiRequestedFailed.type,
//     })
// }
//
// export const updateTransaction = (transaction) => {
//     let url = `/company/transaction/update`
//     return apiCallBegan({
//         url: url,
//         method: 'PUT',
//         data: transaction,
//         onStart: apiRequested.type,
//         onSuccess: updateExistingTransaction.type,
//         onError: apiRequestedFailed.type,
//     })
// }
//
// export const deleteTransaction = (transaction) => {
//     let url = '/company/transaction/remove'
//     return apiCallBegan({
//         url: url,
//         method: 'DELETE',
//         data: transaction,
//         onStart: apiRequested.type,
//         onSuccess: removeTransaction.type,
//         onError: apiRequestedFailed.type,
//     })
// }
