import React, { useState, useCallback, useMemo } from 'react'
import DataSource from 'devextreme/data/data_source'
import { SelectBox } from 'devextreme-react/select-box'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import inventoryService from 'app/services/pages/store/inventory/inventoryServices'
import utilServices from 'app/services/util-services'
import CustomStore from 'devextreme/data/custom_store'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import StockOptionService from '../../../Services/StockOptionService'

const InventoryListReportFilter = ({ mutation }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const inventoryCategoriesLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await inventoryService.getInventoryCategoriesLookupList({ categoryName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService]
    )

    const inventoryCategoriesLookupById = useCallback(
        async (key) => {
            try {
                return await inventoryService.getInventoryCategoriesLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService]
    )

    const inventoryCategoryStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'inventoryCategoryId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await inventoryCategoriesLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return inventoryCategoriesLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        [inventoryCategoriesLookupList, inventoryCategoriesLookupById]
    )

    const inventoryTypeLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await inventoryService.getInventoryTypeLookupList({ typeName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService, handleError]
    )

    const inventoryTypesLookupById = useCallback(
        async (key) => {
            try {
                return await inventoryService.getInventoryTypesLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService, handleError]
    )

    const inventoryTypesStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'invTypeId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await inventoryTypeLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return inventoryTypesLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        [inventoryTypeLookupList, inventoryTypesLookupById]
    )

    const [initialValues, setInitialValues] = useState({
        startDate: '',
        endDate: '',
        productName: '',
        supplierCompanyName: '',
        supplierName: '',
        rackAndShelf: '',
    })

    const [filter, setFilter] = useState({ categoryId: null, inventoryTypeId: null })

    const [stockOptionCurSelection, setStockOptionCurSelection] = useState(null)

    const onFormSubmit = (startDate, endDate, productName, supplierCompanyName, supplierName, rackAndShelf) => {
        mutation.mutate({
            startDate: startDate?.toFilterStartDateTime(),
            endDate: endDate?.toFilterEndDateTime(),
            productName: productName,
            supplierCompanyName: supplierCompanyName,
            supplierName: supplierName,
            rackAndShelf: rackAndShelf,
            stockOptionId: utilServices.isNullOrUndefined(stockOptionCurSelection) ? null : stockOptionCurSelection.id,
            ...filter,
        })
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(
            data.startDate,
            data.endDate,
            data.productName,
            data.supplierCompanyName,
            data.supplierName,
            data.rackAndShelf
        )
        setSubmitting(false)
    }

    const handleClear = () => {
        setFilter({ categoryId: null, inventoryTypeId: null })
        setStockOptionCurSelection(null)
        setInitialValues({
            startDate: '',
            endDate: '',
            productName: '',
            supplierCompanyName: '',
            supplierName: '',
            rackAndShelf: '',
        })
    }

    const onCategorySelectionChanged = (value) => {
        setFilter({ ...filter, categoryId: value?.inventoryCategoryId })
    }

    const onInventoryTypeChange = (value) => {
        setFilter({ ...filter, inventoryTypeId: value?.invTypeId })
    }

    return (
        <Formik validateOnChange={true} initialValues={initialValues} onReset={handleClear} onSubmit={onFilterSubmit}>
            {({ isSubmitting, values, handleChange }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ display: '', borderRadius: 0 }}>
                        <Divider />
                        <Div
                            className='report-filter-parent'
                            style={{
                                display: 'flex',
                                margin: '0% 1.5% 1.5% 1.5%',
                                flexWrap: 'wrap',
                            }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date :</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='startDate'
                                        type='datetime-local'
                                        value={values.startDate}
                                        onChange={handleChange}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='endDate'
                                        type='datetime-local'
                                        value={values.endDate}
                                        onChange={handleChange}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Supplier Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='supplierName'
                                        value={values.supplierName}
                                        onChange={handleChange}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Show Stock</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={StockOptionService.getStockOptionInfo()}
                                        displayExpr={'stockOptionId'}
                                        searchEnabled={true}
                                        onValueChange={setStockOptionCurSelection}
                                        placeholder='All'
                                        showClearButton={true}
                                        value={stockOptionCurSelection}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Product Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='productName'
                                        value={values.productName}
                                        onChange={handleChange}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Company Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='supplierCompanyName'
                                        value={values.supplierCompanyName}
                                        onChange={handleChange}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Rack and Shelf</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='rackAndShelf'
                                        value={values.rackAndShelf}
                                        onChange={handleChange}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Category</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={inventoryCategoryStore}
                                        displayExpr={'categoryName'}
                                        value={filter.categoryId}
                                        searchEnabled={true}
                                        onValueChange={onCategorySelectionChanged}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Type</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={inventoryTypesStore}
                                        displayExpr={'typeName'}
                                        value={filter.inventoryTypeId}
                                        searchEnabled={true}
                                        onValueChange={onInventoryTypeChange}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default InventoryListReportFilter
