export const defaultOrderItemForm = {
    fkInventoryId: null,
    fkStockStorageId: null,
    inventoryBasePrice: 1,
    qtyOrdered: 1,
    qtyZeroPrice: 0,
    orderPrice: '',
    orderPricePercentage: null,
    salePrice: '',
    salePricePercentage: null,
    wholeSaleUntiPrice: null,
    wholeSaleUntiPricePercentage: null,
    discountRate: null,
    taxRate: null,
    additionalTax: null,
    expiryDate: '',
}
