let FreightAmountPaidByTypeService = {}

FreightAmountPaidByTypeService.setFreightAmountPaidByTypeList = [
    { id: 0, FreightAmountPaidByType: 'Customer ' },
    { id: 10, FreightAmountPaidByType: 'Supplier ' },
]

FreightAmountPaidByTypeService.getFreightAmountPaidByType = () => {
    return FreightAmountPaidByTypeService.setFreightAmountPaidByTypeList
}

FreightAmountPaidByTypeService.getGenderTypeData = (row) => {
    const FreightAmountPaidByType = FreightAmountPaidByTypeService.setFreightAmountPaidByTypeList.find(
        (item) => item.id === row.FreightAmountPaidByType
    )
    return FreightAmountPaidByType ? FreightAmountPaidByType.FreightAmountPaidByType : ''
}

export default FreightAmountPaidByTypeService
