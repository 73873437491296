import React from 'react'
import { Stack, Typography } from '@mui/material'
import CardIconText from '@jumbo/shared/CardIconText'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import EuroIcon from '@mui/icons-material/Euro'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'

const HeaderCardList = ({ item }) => {
    const getCardStyle = (icon) => {
        switch (icon) {
            case 'company':
                return { color: 'error.dark', iconComponent: <AddBusinessIcon fontSize='large' /> }
            case 'epos':
                return {
                    color: '#4CAF50',
                    iconComponent: <EuroIcon fontSize='large' />,
                } // Green
            case 'spiral':
                return { color: '#2196F3', iconComponent: <CurrencyExchangeIcon fontSize='large' /> }
            case 'expired':
                return { color: '#FF9800', iconComponent: <LibraryBooksIcon fontSize='large' /> }
            default:
                return { color: '#9C27B0', iconComponent: <AddBusinessIcon fontSize='large' /> }
        }
    }

    return (
        <CardIconText
            icon={getCardStyle(item.icon).iconComponent}
            color={getCardStyle(item.icon).color}
            variant='contained'
            title={
                <Stack direction={'row'} spacing={4} mt={2}>
                    <Typography variant='h4' fontFamily='Lobster, cursive' color={getCardStyle(item.icon).color}>
                        {item.title}
                    </Typography>
                    <Typography variant='h4' color={getCardStyle(item.icon).color}>
                        {item.totalValue}
                    </Typography>
                </Stack>
            }
        />
    )
}

export default HeaderCardList
