import React, { useEffect, useState } from 'react'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper } from '@mui/material'
import { Grid, Table, TableHeaderRow, PagingPanel, VirtualTable } from '@devexpress/dx-react-grid-material-ui'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { getBranchUserList, selectItemById } from 'app/redux/store/features/branches'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'

const LinkedUserGrid = (props) => {
    const [filter, setFilter] = useState()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { branchId } = state
    const { branchUsers, loading, error, branches } = useSelector((state) => state.branches)
    const branch = selectItemById(branches, branchId)

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getBranchUserList({ ...filter, branchId: branch.branchId, skip: 0, take: pageSize }))
    }, [dispatch, filter])

    const dateColumns = ['updatedDate']
    const booleanColumns = ['isActive']
    const [sorting, setSorting] = useState([{ columnName: 'firstName', direction: 'asc' }])
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 25

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, branchId: branch.branchId, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getBranchUserList(currentFilter))
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            branchId: branch.branchId,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getBranchUserList(currentFilter))
        setFilter(currentFilter)
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const Cell = (props) => {
        let style = {
            paddingTop: 3,
            paddingBottom: 3,
        }
        if (props.column.name === 'isActive' || props.column.name == 'Action')
            style = {
                ...style,
                textAlign: 'center',
            }
        else {
            style = {
                ...style,
                textAlign: 'left',
            }
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const BooleanFormatter = ({ value }) => <span>{formatBooleanValue(value)}</span>
    const formatBooleanValue = (value) => (value ? 'Yes' : 'No')
    const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />

    const columns = [
        { name: 'firstName', title: 'First Name' },
        { name: 'lastName', title: 'Last Name' },
        { name: 'phoneNo', title: 'Phone No' },
        { name: 'email', title: 'Email' },
        { name: 'userTypeName', title: 'User Type' },
        { name: 'updatedDate', title: 'Date Updated', hidden: true },
        { name: 'isActive', title: 'Enabled' },
    ]

    const visibleColumns = columns.filter((column) => !column.hidden)

    const columnWidths = [
        { columnName: 'firstName', width: '16%' },
        { columnName: 'lastName', width: '16%' },
        { columnName: 'phoneNo', width: '14%' },
        { columnName: 'email', width: '25%' },
        { columnName: 'userTypeName', width: '21%' },
        { columnName: 'isActive', width: '8%' },
    ]

    const sortingColumnExtensions = [
        { columnName: 'Action', sortingEnabled: false },
        { columnName: 'userTypeName', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={branchUsers.data} columns={visibleColumns}>
                    <DateTypeProvider for={dateColumns} />
                    <BooleanTypeProvider for={booleanColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={branchUsers.totalCount} />
                    <Table columnExtensions={columnWidths} cellComponent={Cell} />
                    <TableHeaderRow showSortingControls cellComponent={Cell} />
                    <SelectionState />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default LinkedUserGrid
