import React, { useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import { getSupplierById, selectItemById, updateSupplier } from 'app/redux/store/features/suppliers.js'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'

const UpdateSupplierForm = (props) => {
    const navigate = useNavigate()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { handleSubmit } = useForm()

    const { suppliers, loading, error } = useSelector((state) => state.suppliers)
    const supplier = selectItemById(suppliers, props.supplierId)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (!apiCallInProgress || loading) return

        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Supplier updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/store/shipments/suppliers/')
        }
    }, [loading])

    useEffect(() => {
        if (utilServices.isNullOrUndefined(supplier.supplierId))
            dispatch(getSupplierById({ supplierId: props.supplierId }))
    }, [])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/shipments/suppliers'),
    }

    const validationRules = {
        companyName: [{ type: 'required', message: 'Company name is required.' }],
        firstName: [{ type: 'required', message: 'First name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Email is invalid.' },
        ],
        phoneNumber: [{ type: 'required', message: 'Phone  number is required.' }],
    }

    const onUpdateSupplier = useCallback(() => {
        setApiCallInProgress(true)
        dispatch(updateSupplier({ ...supplier }))
    }, [supplier])

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onUpdateSupplier)} cssClass='form-group-button-width'>
                <Form mode='form' formData={supplier} showColonAfterLabel={false} cssClass='form-group-button-width'>
                    <GroupItem>
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                            <GroupItem caption='Personal Info' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <SimpleItem dataField='isTaxExempted' editorType='dxCheckBox'>
                                        <Label text='Tax Exempted' />
                                    </SimpleItem>
                                    <SimpleItem dataField='supplierCode' />
                                    <SimpleItem dataField='companyName' validationRules={validationRules.companyName} />
                                    <SimpleItem dataField='firstName' validationRules={validationRules.firstName} />
                                    <SimpleItem dataField='lastName' />
                                    <SimpleItem dataField='barcode' />
                                    <SimpleItem dataField='abn'>
                                        <Label text='ABN' />
                                    </SimpleItem>
                                    <SimpleItem dataField='email' validationRules={validationRules.email} />
                                    <SimpleItem
                                        dataField='fkSupplierCompanyId'
                                        editorType='dxSelectBox'
                                        editorOptions={props.companyEditorOptions}>
                                        <Label text='Supplier Company' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem>
                                <GroupItem caption='Contact Info' cssClass='form-group-item '>
                                    <GroupItem cssClass='form-group-item-margin'>
                                        <SimpleItem dataField='phoneNo' validationRules={validationRules.phoneNumber} />
                                        <SimpleItem dataField='addressLine1'>
                                            <Label text='Address Line One' />
                                        </SimpleItem>
                                        <SimpleItem dataField='addressLine2'>
                                            <Label text='Address Line two' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                                <GroupItem caption='State Info' cssClass='form-group-item '>
                                    <GroupItem cssClass='form-group-item-margin'>
                                        <SimpleItem dataField='country' />
                                        <SimpleItem dataField='state' />
                                        <SimpleItem dataField='suburb' />
                                        <SimpleItem dataField='postCode' />
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                    </GroupItem>
                    <EmptyItem />
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                        <ButtonItem
                            buttonOptions={{
                                disabled: loading,
                                icon: loading ? 'refresh' : 'check',
                                ...submitButtonOptions,
                            }}
                            horizontalAlignment='right'
                        />
                        <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                    </GroupItem>
                </Form>
            </form>
        </React.Fragment>
    )
}

export default UpdateSupplierForm
