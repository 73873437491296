import React, { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AddBranchForm from './AddBranchForm'
import { defaultBranch } from './defaultBranch'
import { useSnackbar } from 'notistack'
import companyAndBranchServices from 'app/services/settings/company/companyAndBranchServices'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import utilServices from 'app/services/util-services'

const AddBranch = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const { id } = state
    const [branchData] = useState({ ...defaultBranch, fkCompanyId: id })
    const { enqueueSnackbar } = useSnackbar()

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const comapanyLookupList = useCallback(async (searchTerm) => {
        try {
            return await companyAndBranchServices.getCompanyLookupList({ companyName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const companyLookupById = useCallback(async (key) => {
        try {
            return await companyAndBranchServices.getCompanyLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const companyLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'companyId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await comapanyLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await companyLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const companyEditorOptions = {
        dataSource: companyLookupStore,
        displayExpr: 'companyName',
        valueExpr: 'companyId',
        searchEnabled: true,
        showClearButton: true,
        readOnly: true,
    }

    const storeTypeLookupList = useCallback(async (searchTerm) => {
        try {
            return await companyAndBranchServices.getStoreTypeLookupList({ typeName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const storeTypeLookupById = useCallback(async (key) => {
        try {
            return await companyAndBranchServices.getStoreTypeLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const storeTypeLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await storeTypeLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (typeof key === 'string') {
                            return utilServices.emptyList()
                        }
                        return await storeTypeLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const storeEditorOptions = {
        dataSource: storeTypeLookupStore,
        displayExpr: 'typeName',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    return (
        <React.Fragment>
            <AddBranchForm
                branchData={branchData}
                companyEditorOptions={companyEditorOptions}
                storeEditorOptions={storeEditorOptions}
            />
        </React.Fragment>
    )
}

export default AddBranch
