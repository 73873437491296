import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import AddDraftOrderForm from './AddDraftOrderForm'
import { defaultDraftOrder } from './defaultDraftOrder'
import { useSnackbar } from 'notistack'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import supplierService from 'app/services/shipments/supplier/supplierServices'
import utilServices from 'app/services/util-services'
import { useDispatch, useSelector } from 'react-redux'
import { addOrder } from 'app/redux/store/features/orders'

const AddDraftOrder = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [draftOrderData] = useState({ ...defaultDraftOrder })
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.orders)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Draft Order added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/store/shipments/draftorders')
        }
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const supplierLookupList = useCallback(async (searchTerm) => {
        try {
            return await supplierService.getSupplierLookupList({ supplierName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierLookupById = useCallback(async (key) => {
        try {
            return await supplierService.getSupplierLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'supplierId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await supplierLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return await utilServices.emptyList()

                        return supplierLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const supplierEditorOptions = {
        dataSource: supplierLookupStore,
        displayExpr: 'supplierName',
        valueExpr: 'supplierId',
        searchEnabled: true,
        showClearButton: true,
    }

    const onAddDraftOrder = async () => {
        setApiCallInProgress(true)
        dispatch(addOrder({ ...draftOrderData }))
    }

    return (
        <React.Fragment>
            <AddDraftOrderForm
                draftOrderData={draftOrderData}
                addDraftOrder={onAddDraftOrder}
                supplierEditorOptions={supplierEditorOptions}
            />
        </React.Fragment>
    )
}

export default AddDraftOrder
