import DraftsIcon from '@mui/icons-material/Drafts'
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard'
import PartyModeIcon from '@mui/icons-material/PartyMode'

export const OrderTimeLineData = [
    {
        id: 1,
        orderStatus: 'Draft',
        icon: <DraftsIcon />,
        title: 'Order Draft Created',
        subTitle: '10 hours ago',
    },
    {
        id: 2,
        orderStatus: 'Approved',
        icon: <DepartureBoardIcon />,
        title: 'Order Approved',
        subTitle: '4 days ago',
    },
    {
        id: 3,
        orderStatus: 'Received',
        icon: <PartyModeIcon />,
        title: 'Order Received',
        subTitle: '1 week ago',
    },
]
