import React, { useState, useEffect, useCallback } from 'react'
import { Button, Chip, List, Typography, useMediaQuery } from '@mui/material'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import LeftInfoPanelItem from './LeftInfoPanelItem'
import Div from '@jumbo/shared/Div'
import { useSelector } from 'react-redux'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'

const LeftInfoPanelList = ({ scrollHeight }) => {
    const [activeButton, setActiveButton] = useState('default')
    const { admin } = useSelector((state) => state.dashboard)
    const { theme } = useJumboTheme()
    const [filteredData, setFilteredData] = useState([])
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        if (admin && admin.renewedList && admin.renewedList.data) {
            const filtered = admin.renewedList.data.filter((item) => {
                return activeButton === 'default' || item.branchTypeName === activeButton
            })
            setFilteredData(filtered)
        }
    }, [admin, activeButton])

    const handleButtonClick = useCallback(
        (item) => {
            setActiveButton(item.id)
        },
        [setActiveButton]
    )

    return (
        <JumboCardQuick
            title={
                <Div
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        },
                    }}>
                    <Typography
                        variant='h3'
                        mb={0}
                        fontFamily='Lobster, cursive'
                        color='primary.dark'
                        sx={{
                            paddingBottom: '10px',
                            minWidth: 245,
                            [theme.breakpoints.down('md')]: {
                                minWidth: '100%',
                                marginBottom: 2,
                            },
                        }}>
                        Recent Renewed List
                    </Typography>
                    <Div sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 1, mt: 0.5, mr: 5 }}>
                        {admin &&
                            admin.renewedList &&
                            admin.renewedList.category?.map((item) => {
                                return (
                                    <Button
                                        key={item.id}
                                        size='small'
                                        variant={item.id === activeButton ? 'contained' : 'outlined'}
                                        color='primary'
                                        disableElevation
                                        onClick={() => handleButtonClick(item)}
                                        sx={{
                                            textTransform: 'none',
                                            p: (theme) => theme.spacing(0, 1.5),
                                        }}>
                                        {item.branchTypeName}
                                        {item.branchName}
                                    </Button>
                                )
                            })}
                    </Div>
                </Div>
            }
            action={<Chip label={'Last ' + 30 + ' days'} color='info' size='small' sx={{ mt: 1.5 }} />}
            headerSx={{
                borderBottom: 1,
                borderBottomColor: 'divider',
                '& .MuiCardHeader-action': {
                    my: -0.75,
                },
            }}
            wrapperSx={{
                p: 0,
                '&:last-child': {
                    pb: 2,
                },
                '& .MuiCollapse-entered:last-child': {
                    '& .MuiListItemButton-root': {
                        borderBottom: 0,
                        borderBottomColor: 'transparent',
                    },
                },
            }}>
            <JumboScrollbar
                autoHeight
                autoHeightMin={scrollHeight || 585}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}>
                <List disablePadding sx={{ overflowX: min ? 'scroll' : 'hidden', minWidth: '600px' }}>
                    {filteredData.map((item, index) => (
                        <LeftInfoPanelItem item={item} key={index} min={min} />
                    ))}
                </List>
            </JumboScrollbar>
        </JumboCardQuick>
    )
}

export default LeftInfoPanelList
