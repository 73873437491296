import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    inventories: { data: [], totalCount: 0 },
    inventoryLookup: { data: [], totalCount: 0 },
    inventoryConfig: { categoryId: null },
    categories: [],
    subCategories: [],
    loading: false,
    error: null,
    refreshing: false,
}

const inventorySlice = createSlice({
    name: 'inventories',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.error = null
            state.loading = true
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getInventoryConfig: (state, action) => {
            state.inventoryConfig = action.payload
        },
        updateInventoryConfig: (state, action) => {
            state.inventoryConfig = action.payload
        },
        getInventories: (state, action) => {
            state.inventories = action.payload
            state.loading = false
            state.refreshing = false
        },
        getExistingInventoryById: (state, action) => {
            const index = state.inventories.data.findIndex(
                (inventory) => inventory.inventoryId === action.payload.inventoryId
            )
            if (index >= 0) state.inventories.data[index] = action.payload
            else {
                state.inventories.data.push(action.payload)
                state.inventories.totalCount++
            }
            state.loading = false
        },
        addNewInventory: (state, action) => {
            state.inventories.data.push(action.payload)
            state.inventories.totalCount++
            state.loading = false
        },
        updateExistingInventory: (state, action) => {
            const index = state.inventories.data.findIndex(
                (inventory) => inventory.inventoryId === action.payload.inventoryId
            )
            if (index >= 0) state.inventories.data[index] = action.payload
            state.loading = false
        },

        removeInventory: (state, action) => {
            const index = state.inventories.data.findIndex(
                (inventory) => inventory.inventoryId === action.payload.inventoryId
            )
            if (index >= 0) {
                state.inventories.data.splice(index, 1)
                state.inventories.totalCount--
            }
            state.loading = false
        },

        getInventoryLookup: (state, action) => {
            state.inventoryLookup = action.payload
            state.loading = false
        },
        getExistingInventoryLookupById: (state, action) => {
            const index = state.inventoryLookup.data.findIndex(
                (inventory) => inventory.invClassId === action.payload.invClassId
            )
            if (index >= 0) state.inventoryLookup.data[index] = action.payload
            state.loading = false
        },

        getCategoryLookup: (state, action) => {
            state.categories = action.payload
            state.loading = false
        },
        getExistingCategoryById: (state, action) => {
            const index = state.categories.data.findIndex(
                (inventory) => inventory.invCategoryId === action.payload.invCategoryId
            )
            if (index >= 0) state.categories.data[index] = action.payload
            state.loading = false
        },

        getSubCategoryLookup: (state, action) => {
            state.subCategories = action.payload
            state.loading = false
        },
        getExistingSubCategoryById: (state, action) => {
            const index = state.subCategories.data.findIndex(
                (inventory) => inventory.invSubCategoryId === action.payload.invSubCategoryId
            )
            if (index >= 0) state.subCategories.data[index] = action.payload
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.inventoryId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }
export const {
    apiRequested,
    refreshRequested,
    apiRequestedFailed,
    getInventoryConfig,
    updateInventoryConfig,
    getInventoryLookup,
    getExistingInventoryLookupById,
    getInventories,
    getExistingInventoryById,
    addNewInventory,
    updateExistingInventory,
    removeInventory,
    getCategoryLookup,
    getExistingCategoryById,
    getSubCategoryLookup,
    getExistingSubCategoryById,
} = inventorySlice.actions
export default inventorySlice.reducer

export const getInventoryList = (filter, refresh = false) => {
    let url = `/Inventory/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getInventories.type,
        onError: apiRequestedFailed.type,
    })
}

export const getInventoryById = (filter) => {
    let url = `/Inventory/${filter.inventoryId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingInventoryById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addInventory = (inventory) => {
    let url = `/Inventory/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: inventory,
        onStart: apiRequested.type,
        onSuccess: addNewInventory.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateInventory = (inventory) => {
    let url = `/Inventory/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: inventory,
        onStart: apiRequested.type,
        onSuccess: updateExistingInventory.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteInventory = (inventory) => {
    let url = '/Inventory/remove'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: inventory,
        onStart: apiRequested.type,
        onSuccess: removeInventory.type,
        onError: apiRequestedFailed.type,
    })
}

export const getInventoryLookupList = (filter) => {
    let url = filter?.invName ? `/Inventory/lookup/list?search=${filter.invName}` : `/Inventory/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getInventoryLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getInventoryLookupById = (filter) => {
    let url = `/Inventory/lookup/${filter.invClassId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingInventoryLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCategoryLookupList = (filter) => {
    let url = filter?.categoryName
        ? `/Inventory/category/lookup/list?search=${filter.categoryName}`
        : `/Inventory/category/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getCategoryLookup.type,
        onError: apiRequestedFailed.type,
    })
}
export const getCategoryById = (filter) => {
    let url = `/Inventory/category/${filter.categoryId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingCategoryById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSubCategoryLookupList = (filter) => {
    let url = filter?.subCategoryName
        ? `/Inventory/subcategory/lookup/list?fkCategoryId=${filter.categoryId}&search=${filter.subCategoryName}`
        : filter?.categoryId
        ? `/Inventory/subcategory/lookup/list?fkCategoryId=${filter.categoryId}`
        : `/Inventory/subcategory/lookup/list`

    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSubCategoryLookup.type,
        onError: apiRequestedFailed.type,
    })
}
export const getSubCategoryById = (filter) => {
    let url = `/Inventory/subcategory/${filter.subCategoryId}`

    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingSubCategoryById.type,
        onError: apiRequestedFailed.type,
    })
}
