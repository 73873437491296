import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { updateOrder } from 'app/redux/store/features/orders'
import FreightAmountPaidByTypeService from './FreightAmountPaidByTypeService'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from 'app/services/util-services'
import RadioGroup from 'devextreme-react/radio-group'
import supplierService from 'app/services/shipments/supplier/supplierServices'

const UpdateDraftOrderForm = (props) => {
    const navigate = useNavigate()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.orders)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (!apiCallInProgress || loading) return

        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Draft Order data updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/store/shipments/draftorders')
        }
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/shipments/draftorders'),
    }

    const priorityEntitiesDiscountFixed = [
        { id: false, text: 'Discount Percentage' },
        { id: true, text: 'Discount Fixed' },
    ]

    const onSelectionChangedDiscountFixed = (e) => {
        props.order.isDiscountFixed = e.value
    }

    const priorityEntitiesDiscountEach = [
        { id: false, text: 'Discount Single Item' },
        { id: true, text: 'Discount Each Item' },
    ]

    const onSelectionChangedDiscountEach = (e) => {
        props.order.isDiscountEach = e.value
    }

    const FreightAmountPaidByTypeEditorOptions = {
        dataSource: FreightAmountPaidByTypeService.getFreightAmountPaidByType(),
        displayExpr: 'FreightAmountPaidByType',
        valueExpr: 'id',
        showClearButton: true,
    }

    const supplierLookupList = useCallback(async (searchTerm) => {
        try {
            return await supplierService.getSupplierLookupList({ supplierName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierLookupById = useCallback(async (key) => {
        try {
            return await supplierService.getSupplierLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'supplierId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await supplierLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return await utilServices.emptyList()

                        return supplierLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const supplierEditorOptions = {
        dataSource: supplierLookupStore,
        displayExpr: 'supplierName',
        valueExpr: 'supplierId',
        searchEnabled: true,
        showClearButton: true,
    }

    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })

    const onUpdateDraftOrder = useCallback(async () => {
        setApiCallInProgress(true)
        dispatch(updateOrder({ ...props.order }))
    }, [props.order])

    return (
        <form onSubmit={handleSubmit(onUpdateDraftOrder)}>
            <Form mode='form' formData={props.order} showColonAfterLabel={false}>
                <GroupItem>
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                        <GroupItem caption='Order Details' cssClass='form-group-item'>
                            <GroupItem cssClass='form-group-item-margin'>
                                <SimpleItem editorType='dxDateBox' dataField='orderDate'>
                                    <Label text='Order Date' />
                                </SimpleItem>
                                <SimpleItem dataField='purchaseOrderNo'>
                                    <Label text='Purchase Order No' />
                                </SimpleItem>
                                <SimpleItem dataField='expectedDate' editorType='dxDateBox'>
                                    <Label text='Delivery Date' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='fkSupplierId'
                                    editorType='dxSelectBox'
                                    editorOptions={supplierEditorOptions}>
                                    <Label text='Supplier' />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem>
                            <GroupItem caption='Freight Charges' cssClass='form-group-item '>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <SimpleItem dataField='freightAmount' editorType='dxNumberBox'>
                                        <Label text='Freight Amount' />
                                    </SimpleItem>
                                    <SimpleItem dataField='freightAmount' editorType='dxNumberBox'>
                                        <Label text='Bity Number' />
                                    </SimpleItem>
                                    <SimpleItem
                                        editorType='dxSelectBox'
                                        dataField='FreightAmountPaidByType'
                                        editorOptions={FreightAmountPaidByTypeEditorOptions}
                                    />
                                    <SimpleItem dataField='freightComments'>
                                        <Label text='Bity Provider' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption='Configuration' cssClass='form-group-item '>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <RadioGroup
                                        items={priorityEntitiesDiscountFixed}
                                        valueExpr='id'
                                        displayExpr='text'
                                        layout='vertical !important'
                                        defaultValue={props.order.isDiscountFixed}
                                        onValueChanged={onSelectionChangedDiscountFixed}
                                    />
                                    <RadioGroup
                                        items={priorityEntitiesDiscountEach}
                                        valueExpr='id'
                                        displayExpr='text'
                                        defaultValue={props.order.isDiscountEach}
                                        onValueChanged={onSelectionChangedDiscountEach}
                                    />
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                    </GroupItem>
                </GroupItem>
                <EmptyItem />
                <GroupItem>
                    <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                    <ButtonItem
                        buttonOptions={{
                            disabled: loading,
                            icon: loading ? 'refresh' : 'check',
                            ...submitButtonOptions,
                        }}
                        horizontalAlignment='right'
                    />
                    <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                </GroupItem>
            </Form>
        </form>
    )
}

export default UpdateDraftOrderForm
