export const defaultDraftOrder = {
    orderDate: '',
    purchaseOrderNo: '',
    expectedDate: null,
    fkSupplierId: null,
    comments: '',
    freightAmount: 0,
    freightBNumber: '',
    freightComments: '',
    isDiscountFixed: false,
    isDiscountEach: false,
    FreightAmountPaidByType: 0,
}
