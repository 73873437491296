import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, ColCountByScreen, EmptyItem, GroupItem, Label } from 'devextreme-react/form'
import 'devextreme/ui/text_area'
import { getHostingLookupList } from 'app/redux/store/features/company'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'

const UpdateCompaniesForm = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const { hostingLookups } = useSelector((state) => state.companies)
    const { loading } = useSelector((state) => state.companies)

    useEffect(() => {
        dispatch(getHostingLookupList())
    }, [])

    const hostingTypeLookupStore = new DataSource({
        store: new ArrayStore({
            key: 'hostingOptionId',
            data: hostingLookups,
            totalCount: hostingLookups.length,
        }),
        reshapeOnPush: true,
    })

    const hostingTypeLookupEditorOptions = {
        dataSource: hostingTypeLookupStore,
        displayExpr: 'hostingOptionName',
        valueExpr: 'hostingOptionId',
        searchEnabled: true,
        showClearButton: true,
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/settings/companies'),
    }

    const validationRules = {
        companyName: [{ type: 'required', message: 'Company name is required.' }],
        contactName: [{ type: 'required', message: 'Contact name is required.' }],
        contactNo: [{ type: 'required', message: 'Contact No is required.' }],
    }

    const phoneEditorOptions = { mask: '+XX (X00) 000-0000', maskRules: { X: /[02-9]/ } }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(props.updateCompany)}>
                <Form
                    mode='form'
                    formData={props.companyData}
                    showColonAfterLabel={false}
                    showValidationSummary={false}>
                    <GroupItem cssClass='form-group-item' caption='Update Company Details'>
                        <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem cssClass='form-group-item' colSpan={6}>
                            <GroupItem colCount={2}>
                                <SimpleItem dataField='hasEposBranches' editorType='dxCheckBox'>
                                    <Label text='Epos Branches' />
                                </SimpleItem>
                                <SimpleItem dataField='hasSpiralBranches' editorType='dxCheckBox'>
                                    <Label text='Spiral Branches' />
                                </SimpleItem>
                                <SimpleItem dataField='isActive' editorType='dxCheckBox'>
                                    <Label text='isActive' />
                                </SimpleItem>
                            </GroupItem>
                            <SimpleItem dataField='companyName' validationRules={validationRules.companyName} />
                            <SimpleItem dataField='contactName' validationRules={validationRules.contactName} />
                            <SimpleItem
                                dataField='contactNo'
                                editorOptions={phoneEditorOptions}
                                validationRules={validationRules.contactNo}
                            />
                            <SimpleItem
                                dataField='fkHostingOptionId'
                                editorType='dxSelectBox'
                                editorOptions={hostingTypeLookupEditorOptions}>
                                <Label text='Hosting Option' />
                            </SimpleItem>
                            <SimpleItem dataField='companyTag'>
                                <Label text='Tag (lower case and up to 10 digits)' />
                            </SimpleItem>
                            <SimpleItem dataField='clientVersion'>
                                <Label text='Client Version' />
                            </SimpleItem>
                            <SimpleItem dataField='comments' editorType='dxTextArea' />
                        </GroupItem>
                    </GroupItem>
                    <EmptyItem />
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                        <ButtonItem
                            buttonOptions={{
                                disabled: loading,
                                icon: loading ? 'refresh' : 'check',
                                ...submitButtonOptions,
                            }}
                            horizontalAlignment='right'
                        />
                        <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                    </GroupItem>
                </Form>
            </form>
        </React.Fragment>
    )
}

export default UpdateCompaniesForm
