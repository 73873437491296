import jwtAuthAxios from 'app/services/auth/jwtAuth'
const companyAndBranchServices = {}

companyAndBranchServices.getCompanyLookupList = async (filter) => {
    let url = filter?.companyName ? `/Company/lookup/list?search=${filter.companyName}` : `/Company/lookup/list`

    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

companyAndBranchServices.getCompanyLookupById = async (key) => {
    let url = `/Company/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

companyAndBranchServices.getStoreTypeLookupList = async (filter) => {
    let url = filter?.typeName
        ? `/company/branch/type/lookup/list?search=${filter?.typeName}`
        : `/company/branch/type/lookup/list`

    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

companyAndBranchServices.getStoreTypeLookupById = async (key) => {
    let url = `/company/branch/type/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

companyAndBranchServices.getBranchLookupList = async (filter) => {
    let url = filter?.branchName
        ? `/company/${filter.companyId}/branch/lookup/list?search=${filter.branchName}`
        : `/company/${filter.companyId}/branch/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

companyAndBranchServices.getBranchById = async (companyId, key) => {
    let url = `/company/${companyId}/branch/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

export default companyAndBranchServices
