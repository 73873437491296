import React from 'react'
import ActiveReportHeader from './ActiveReportHeader'
import ActiveReportFooter from './ActiveReportFooter'
import { useParams } from 'react-router-dom'
import ActiveReportView from './ActiveReportView'
import DailySaleReport from '../epos/SalesReportList/components/dailysalereport/DailySaleReport'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery } from '@mui/material'

const ActiveReport = React.forwardRef(({ onRefresh }, ref) => {
    const { id } = useParams()
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    if (!id) {
        return <React.Fragment>{!min && <DailySaleReport />}</React.Fragment>
    } else {
        return (
            <React.Fragment>
                <ActiveReportHeader />
                <ActiveReportView />
                <ActiveReportFooter />
            </React.Fragment>
        )
    }
})

ActiveReport.defaultProps = {
    onRefresh: () => {},
}

export default ActiveReport
