import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Paper, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
    CustomPaging,
    PagingState,
    SortingState,
    SelectionState,
    SummaryState,
    IntegratedSummary,
    DataTypeProvider,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSummaryRow,
    VirtualTable,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { CurrencyFormatterSummary } from 'app/utils/components/formatCurrency/formatCurrency'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { useSelector } from 'react-redux'
import { useJumboTheme } from '@jumbo/hooks'

const OrderItemsListDataGrid = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
    txnItemListResponseData,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { loading, error } = useSelector((state) => state.orders)
    const [sorting, setSorting] = useState([{ columnName: 'invNo', direction: 'asc' }])
    const pageSize = 10
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const CurrencyTypeProvider = (props) => (
        <DataTypeProvider formatterComponent={CurrencyFormatterSummary} {...props} />
    )

    const QtyTypeProvider = (props) => <DataTypeProvider {...props} />

    const columns = [
        { name: 'invNo', title: 'Inventory No' },
        { name: 'invName', title: 'Product Name' },
        { name: 'qtyOrdered', title: 'Qty Ordered' },
        { name: 'qtyReceived', title: 'Qty Received' },
        {
            name: 'inventoryBasePrice',
            title: 'Base Price',
        },
        { name: 'marketPrice', title: 'Market Price', getCellValue: (row) => formatCurrency(row.marketPrice) },
        { name: 'orderPrice', title: 'Order Price', getCellValue: (row) => formatCurrency(row.orderPrice) },
        {
            name: 'orderPricePercentage',
            title: 'Order %',
            getCellValue: (row) => formatCurrency(row.orderPricePercentage),
        },
        { name: 'salePrice', title: 'Sale Price', getCellValue: (row) => formatCurrency(row.salePrice) },
        {
            name: 'salePricePercentage',
            title: 'Sale %',
            getCellValue: (row) => formatCurrency(row.salePricePercentage),
        },
        { name: 'discountAmount', title: 'Discount', getCellValue: (row) => formatCurrency(row.discountAmount) },
        { name: 'taxAmount', title: 'Tax', getCellValue: (row) => formatCurrency(row.taxAmount) },
        { name: 'totalAmount', title: 'Total' },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'invNo', width: min ? '130px' : '9%', minWidth: '130px', wordWrapEnabled: true },
            { columnName: 'invName', width: min ? '100px' : '9%', minWidth: '100px', wordWrapEnabled: true },
            {
                columnName: 'qtyOrdered',
                align: 'center',
                width: min ? '100px' : '8%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'qtyReceived',
                align: 'center',
                width: min ? '110px' : '8%',
                minWidth: '110px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'inventoryBasePrice',
                align: 'right',
                width: min ? '80px' : '8%',
                minWidth: '80px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'marketPrice',
                align: 'right',
                width: min ? '100px' : '8%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'orderPrice',
                align: 'right',
                width: min ? '90px' : '8%',
                minWidth: '90px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'orderPricePercentage',
                align: 'right',
                width: min ? '90px' : '7%',
                minWidth: '90px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'salePrice',
                align: 'right',
                width: min ? '80px' : '7%',
                minWidth: '80px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'salePricePercentage',
                align: 'right',
                width: min ? '80px' : '6%',
                minWidth: '80px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'discountAmount',
                align: 'right',
                width: min ? '100px' : '7%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'taxAmount',
                align: 'right',
                width: min ? '100px' : '7%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'totalAmount',
                align: 'right',
                width: min ? '100px' : '8%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const Cell = (props) => {
        let style = {
            height: '42px',
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const totalSummaryItems = [
        { columnName: 'qtyOrdered', type: 'sum' },
        { columnName: 'qtyReceived', type: 'sum' },
        { columnName: 'totalAmount', type: 'sum' },
    ]

    const qtyColumns = ['qtyOrdered', 'qtyReceived']
    const currencyColumns = ['totalAmount']

    const messages = {
        sum: 'Total',
    }
    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={txnItemListResponseData.data} columns={columns}>
                <CurrencyTypeProvider for={currencyColumns} />
                <QtyTypeProvider for={qtyColumns} />
                <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={txnItemListResponseData.totalCount} />
                <SummaryState totalItems={totalSummaryItems} />
                <IntegratedSummary />
                <Table columnExtensions={columnWidths} cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <TableSummaryRow messages={messages} />
                <SelectionState />
                <TableSelection selectByRowClick showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default OrderItemsListDataGrid
