import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Link, Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import { getOrderById } from 'app/redux/store/features/orders'
import { useJumboTheme } from '@jumbo/hooks'

const ApprovedOrdersData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { orders, loading, error } = useSelector((state) => state.orders)
    const dateColumns = ['shipmentDate']
    const [sorting, setSorting] = useState([{ columnName: 'shipmentNo', direction: 'asc' }])
    const pageSize = 25
    const rows = orders.data && orders.data.length > 0 ? orders.data : []
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md
    const onlineShipment = false

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getOrderById({ orderId: id, onlineShipment: onlineShipment }))
        navigate(`/store/shipments/orders/details/${id}/${onlineShipment}`, {
            state: { orderId: id, onlineShipment: onlineShipment },
        })
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.orderId)}
                sx={{
                    '&:hover': {
                        color: 'action.active', // Change this to the color you want on hover
                        cursor: 'pointer',
                    },
                }}>
                {row.shipmentNo}
            </Link>
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onNavigatingToDetail(row.orderId)}>
                    <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.orderId)
                }}
            />
        )
    }

    const columns = [
        { name: 'shipmentNo', title: 'Order No', getCellValue: renderLinkCell },
        { name: 'shipmentDate', title: 'Order Date' },
        { name: 'shipmentType', title: 'Order Type' },
        { name: 'supplierName', title: 'Supplier Name' },
        { name: 'shipmentStatus', title: 'Status' },
        { name: 'txnTotal', title: 'Total Amount', getCellValue: (row) => formatCurrency(row.txnTotal) },
        { name: 'discount', title: 'Discount', getCellValue: (row) => formatCurrency(row.discount) },
        { name: 'totalPayment', title: 'Total Payment', getCellValue: (row) => formatCurrency(row.totalPayment) },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'shipmentNo', width: min ? '100px' : '10%', minWidth: '100px', wordWrapEnabled: true },
            { columnName: 'shipmentDate', width: min ? '130px' : '13%', minWidth: '130px', wordWrapEnabled: true },
            { columnName: 'shipmentType', width: min ? '90px' : '9%', minWidth: '90px', wordWrapEnabled: true },
            { columnName: 'supplierName', wordWrapEnabled: true, width: min ? '170px' : '20%', minWidth: '170px' },
            { columnName: 'shipmentStatus', width: min ? '100px' : '10%', minWidth: '100px', wordWrapEnabled: true },
            {
                columnName: 'txnTotal',
                align: 'right',
                width: min ? '100px' : '10%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'discount',
                align: 'right',
                width: min ? '110px' : '10%',
                minWidth: '110px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'totalPayment',
                align: 'right',
                width: min ? '110px' : '10%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            { columnName: 'Action', align: 'center', width: min ? '90px' : '8%', minWidth: '90px' },
        ],
        [min]
    )
    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={rows} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={orders.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default ApprovedOrdersData
