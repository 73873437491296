import React, { useState, useEffect } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend } from 'recharts'
import { Chip, Typography } from '@mui/material'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { formattedDateWithoutYear, formattedDate } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import LoaderOverlay from 'app/utils/components/LoaderOverlay'
import LoaderOverlayDashboard from 'app/utils/components/LoaderOverlayDashboard'
import Div from '@jumbo/shared/Div'

const BannerLeft = () => {
    const { sale, loading } = useSelector((state) => state.dashboard)
    const [yAxisDomain, setYAxisDomain] = useState([0, 100])

    const formatYAxis = (tickItem) => {
        return `$${tickItem}`
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className='custom-tooltip'>
                    <p className='label'>{formattedDate(label)}</p>
                    <p className='data'>{`Daily Sale: ${formatCurrency(payload[0].value)}`}</p>
                </div>
            )
        }

        return null
    }

    const renderTick = (props) => {
        const { x, y, payload } = props
        return (
            <text
                x={x}
                y={y}
                dy={4}
                textAnchor='end'
                style={{
                    color: 'info',
                    fontSize: 'small',
                }}>
                {formatYAxis(payload.value)}
            </text>
        )
    }

    useEffect(() => {
        let minTotalSale = Infinity
        let maxTotalSale = -Infinity

        sale.leftPanel.data.forEach((item) => {
            if (item.totalSale < minTotalSale) {
                minTotalSale = item.totalSale
            }
            if (item.totalSale > maxTotalSale) {
                maxTotalSale = item.totalSale
            }
        })

        setYAxisDomain([Math.floor(minTotalSale)])
    }, [sale.leftPanel.data])

    return (
        <Grid container>
            <Grid item xs={12}>
                {sale && sale.leftPanel ? (
                    <JumboCardQuick
                        title={
                            <Typography fontFamily='Lobster, cursive' variant={'h3'} mb={0} color={'info.dark'}>
                                <LoaderOverlayDashboard loading={loading} />
                                Daily Sale
                            </Typography>
                        }
                        action={<Chip label={'Last ' + 7 + ' days'} color={'info'} size={'small'} />}
                        wrapperSx={{ pt: 0 }}>
                        <Div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                            <Div style={{ width: '600px' }}>
                                <ResponsiveContainer height={200} width='100%' debounce={200}>
                                    <LineChart data={sale.leftPanel.data}>
                                        <Tooltip content={<CustomTooltip />} cursor={false} />
                                        <XAxis
                                            dataKey='saleDate'
                                            tickLine={false}
                                            axisLine={false}
                                            tickFormatter={(tick) => formattedDateWithoutYear(tick, false)}
                                        />
                                        <YAxis domain={yAxisDomain} tick={renderTick} />
                                        <Legend />
                                        <Line
                                            type='monotone'
                                            dataKey='totalSale'
                                            name='Daily Sale'
                                            stroke='#3EC3D0'
                                            strokeWidth={2}
                                            dot={{ stroke: '#3EC3D0', fill: '#fff', strokeWidth: 2, r: 4 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                ) : (
                    <div>Loading...</div>
                )}
            </Grid>
        </Grid>
    )
}

export default BannerLeft
