let InventoryBasePriceService = {}

InventoryBasePriceService.setBasePriceList = [
    { id: 1, inventoryBasePrice: 'Default' },
    { id: 2, inventoryBasePrice: 'Sale Price' },
    { id: 3, inventoryBasePrice: 'Order Price' },
]

InventoryBasePriceService.getBasePriceService = () => {
    return InventoryBasePriceService.setBasePriceList
}

export default InventoryBasePriceService
