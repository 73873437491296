import jwtAuthAxios from 'app/services/auth/jwtAuth'
const supplierService = {}

supplierService.getSupplierLookupList = async (filter) => {
    let url = filter?.supplierName
        ? `/shipment/Supplier/lookup/list?search=${filter.supplierName}`
        : `/shipment/Supplier/lookup/list`

    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

supplierService.getSupplierLookupById = async (key) => {
    let url = `/shipment/Supplier/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

supplierService.getSupplierById = async (key) => {
    let url = `/shipment/Supplier/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

supplierService.getSupplierCompanyLookupList = async (filter) => {
    let url = filter?.supplierCompanyName
        ? `/shipment/Supplier/company/lookup/list?search=${filter.supplierCompanyName}`
        : `/shipment/Supplier/company/lookup/list`

    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

supplierService.getSupplierCompanyLookupById = async (key) => {
    let url = `/shipment/Supplier/company/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

export default supplierService
